import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { BDMdashboardFilter, BDMdashboardGroupMap, TopDashboardDetail } from '../../../../types/dashboard-types';
import { fetchDashboardJWT, getMongoChartToken } from 'src/app/modules/AgencyCampaign/network/dashboardCrud';
import { PermissionRole, TokenInfo } from '../../../../../Auth/types/auth-types';
import { fetchDownlineDivision } from '../../../../../KpiDashboard/network/agentCrud';

import {
  BDMdashboardDataUat,
  DashboardLibraryUat,
  TopDashboardsUat,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-uat';
import {
  BDMdashboardDataProd,
  DashboardLibraryProd,
  TopDashboardsProd,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-prod';
import { useJWT } from 'src/app/common/utils';
import { decodeJWT } from 'src/app/modules/Auth/network/authCrud';
import { useDispatch } from 'react-redux';
import { setTokenInfo } from 'src/app/modules/Auth/network/authSlice';
import { configManager } from 'src/app/common/utils/app-configs.util';

const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
const chartSubscriptionKey = window.envConfig['REACT_APP_MONGODB_CHART_SUBSCRIPTION_KEY'];
var libarary = DashboardLibraryUat;
var topDashboards = TopDashboardsUat;
var dataChartId = BDMdashboardDataUat;

type ChartListProps = {
  filter: any;
  onRefresh?: boolean;
  refreshdata: () => void;
  setExcelData: (data: any) => void;
  getDataList: (list: any) => void;
  user: any | undefined;
};

const DashboardDetailPage: FC<ChartListProps> = ({
  filter,
  onRefresh,
  refreshdata,
  setExcelData,
  getDataList,
  user,
}) => {
  const dispatch = useDispatch();
  //PACS Customization: useJWT hook to get token info
  const jwtDetail = useJWT();
  const region = jwtDetail.region;
  const channel = jwtDetail.channel;

  //PACS Customization: this function is not used
  // const getEnv = useMemo(() => {
  //   const env = window.envConfig['REACT_APP_ENV'];
  //   if (env === 'uat') {
  //     libarary = DashboardLibraryUat;
  //     topDashboards = TopDashboardsUat;
  //     dataChartId = BDMdashboardDataUat;
  //   } else {
  //     if (env === 'prod') {
  //       libarary = DashboardLibraryProd;
  //       topDashboards = TopDashboardsProd;
  //       dataChartId = BDMdashboardDataProd;
  //     }
  //   }
  //   const jwt = window.localStorage.getItem('jwt');
  //   if (jwt) {
  //     const jwtDetail = jwt_decode(jwt) as TokenInfo;
  //     region = jwtDetail.region;
  //     channel = jwtDetail.channel;
  //   }
  // }, []);

  /* PACS Customization: decode jwt in useEffect */
  useEffect(() => {
    const token = window.localStorage.getItem('jwt');

    if (token) {
      decodeJWT(token).then((jwt) => dispatch(setTokenInfo(jwt)));
    }
  }, []);
  /* PACS Customization: decode jwt in useEffect */

  const sdk = new ChartsEmbedSDK({
    baseUrl: configManager.getConfig('global-system')?.mongoChart?.baseUrl,
    getUserToken: async function () {
      const response = await getMongoChartToken();
      return response.accessToken;
    },
  });
  var chartID = '';

  for (var i = 0; i < libarary.report.length; i++) {
    var object = libarary.report[i];
    if (object.chartName == filter.chartName) {
      chartID = object.chartID;
      if (object.chartType == filter.chartType) {
        chartID = object.chartID;
        break;
      }
    }
    if (chartID == '') {
      chartID = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277';
    }
  }

  const [myFilter, setMyFilter] = useState<any>({
    type: '',
    assignedDate: [],
    leadSource: '',
    campaignId: '',
    consultantCode: '',
    campaignStatus: '',
    campaignType: '',
    campaignRecordType: '',
    leadSubSource: '',
    startDate: [],
    campaignStartDt: [],
    assignDate: [],
    unitCode: '',
    businessManagerName: '',
    consultantName: '',
    region: region,
    channel: channel,
  });

  const dataChartDiv = useRef<HTMLDivElement>(null);
  const chartDiv = useRef<HTMLDivElement>(null);
  const chartDivLeft = useRef<HTMLDivElement>(null);
  const chartDivRight = useRef<HTMLDivElement>(null);
  const chartDivLeftMiddle = useRef<HTMLDivElement>(null);
  const chartDivRightMiddle = useRef<HTMLDivElement>(null);
  const chartDivLeftBottom = useRef<HTMLDivElement>(null);
  const chartDivRightBottom = useRef<HTMLDivElement>(null);
  const promiseArray: { chartName: string; promise: Promise<object> }[] = [];

  const [rendered, setRendered] = useState(false);
  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: chartID,
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [moreChartData, setMoreChartData] = useState({
    idList: [{ chartID: '', chartName: '' }],
    promiseArray: [{ chartName: '', promise: Promise.resolve({}) }],
    filterConfig: {},
  });
  const [dataChart, reDataChart] = useState(
    sdk.createChart({
      chartId: dataChartId,
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [moreIdList, setMoreIdList] = useState<Array<string>>([]);

  const [chartLeft, setChartLeft] = useState(
    sdk.createChart({
      chartId: topDashboards.get(filter.chartName)?.dashboardLeft ?? '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [chartRight, setChartRight] = useState(
    sdk.createChart({
      chartId: topDashboards.get(filter.chartName)?.dashboardRight ?? '6267bd98-d717-4757-8bff-7d34608e2384',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [chartLeftMiddle, setChartLeftMiddle] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [chartRightMiddle, setChartRightMiddle] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [chartLeftBottom, setChartLeftBottom] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [chartRightBottom, setChartRightBottom] = useState(
    sdk.createChart({
      chartId: '6274f450-2e4a-4b45-883b-d81646a94385',
      height: 800,
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [isShowChart, setIsShowChart] = useState<Boolean>(false);

  const getTopCharts = (topChartsName: TopDashboardDetail) => {
    let isChart3 = topDashboards.get(filter.chartName)?.type == 'Unique' ? true : false;
    let isChart2 = topDashboards.get(filter.chartName)?.type == 'Among' ? true : false;

    if (chartDivLeft.current) {
      let left = sdk.createChart({
        chartId: topChartsName.dashboardLeft,
        height: isChart3 ? 400 : 200,
        width: isChart3 ? 800 : 200,
        theme: 'light',
        filter: { $and: [{ region: region }, { channel: channel }] },
      });
      setChartLeft(left);
      left
        .render(chartDivLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRight.current && topChartsName.dashboardRight) {
      let right = sdk.createChart({
        chartId: topChartsName.dashboardRight,
        height: isChart2 ? 400 : 200,
        width: isChart2 ? 800 : 200,
        theme: 'light',
        filter: { $and: [{ region: region }, { channel: channel }] },
      });
      setChartRight(right);
      right
        .render(chartDivRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
  };

  const getMoreCharts = (chartList: Array<string>, chartType: string, filterConfig: object) => {
    let idList: { chartID: string; chartName: string }[] = [];
    for (var i = 0; i < libarary.report.length; i++) {
      if (chartList.indexOf(libarary.report[i].chartName) != -1 && libarary.report[i].chartType == chartType) {
        idList.push({ chartID: libarary.report[i].chartID, chartName: libarary.report[i].chartName });
      }
    }

    let width = (window.innerWidth - 40) / 2;
    if (dataChartDiv.current) {
      reDataChart(
        sdk.createChart({
          chartId: dataChartId,
          height: 800,
          theme: 'light',
          filter: { $and: [{ region: region }, { channel: channel }] },
        }),
      );

      dataChart
        .render(dataChartDiv.current)
        .then(() => {
          dataChart
            .getData()
            .then((result) => {
              const agentCode = user?.sub || '';
              if (user?.role === PermissionRole.MANAGER) {
                fetchDownline(agentCode).then((unitCodes) => {
                  type resultType = keyof typeof result;
                  type fieldsType = keyof typeof fields;
                  type dataType = keyof typeof data[0];

                  const unitCodeLablesByAgentCode = unitCodes.map((unitCode: any) => {
                    return unitCode.groupCode && unitCode.groupCode.substring(0, 1) === 'U'
                      ? unitCode.groupCode.substring(1, unitCode.groupCode.length)
                      : unitCode.groupCode;
                  });

                  let fields = result['fields' as resultType] as object;
                  let data = result['documents' as resultType] as any;

                  const unitCodeFieldName: string[] | undefined = Object.entries(fields).find(
                    (entry: any) => entry[1] === 'unitCode',
                  );

                  if (unitCodeFieldName && unitCodeFieldName[0]) {
                    data = data.filter((chartData: any) => {
                      return unitCodeLablesByAgentCode.includes(chartData[unitCodeFieldName[0]]);
                    });
                  }

                  let nameObj = {
                    leadSource: '',
                    unitCode: '',
                    businessManagerName: '',
                    consultantName: '',
                  };

                  type nameType = keyof typeof nameObj;

                  // fields[fieldName as fieldsType] -> chart's field name
                  for (const fieldName in fields) {
                    nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
                  }

                  let dataList: Record<string, any[] | any> = {
                    leadSource: [],
                    unitCode: [],
                    businessManagerName: [],
                    consultantName: [],
                    region: [],
                    channel: [],
                  };

                  // filter duplicate choices
                  data.forEach((item: any) => {
                    for (const key in dataList) {
                      if (
                        !!item[nameObj[key as nameType] as dataType] &&
                        !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                      ) {
                        if (key === 'unitCode') {
                          if (unitCodeLablesByAgentCode.includes(item[nameObj[key as nameType] as dataType])) {
                            dataList[key].push(item[nameObj[key as nameType] as dataType]);
                          }
                        } else if (key === 'consultantName') {
                          if (filter.unitCode) {
                            if (item[nameObj['unitCode' as nameType] as dataType] === filter.unitCode) {
                              dataList[key].push(item[nameObj[key as nameType] as dataType]);
                            }
                          } else {
                            if (item[nameObj['unitCode' as nameType] as dataType] === dataList.unitCode[0]) {
                              dataList[key].push(item[nameObj[key as nameType] as dataType]);
                            }
                          }
                        } else {
                          dataList[key].push(item[nameObj[key as nameType] as dataType]);
                        }
                      }
                    }
                  });
                  getDataList(dataList);

                  const initalUnitCode =
                    dataList && dataList.unitCode && dataList.unitCode.length > 0 ? dataList.unitCode[0] : '';

                  if (Object.keys(filterConfig).length === 2) {
                    filterConfig = { unitCode: initalUnitCode, region, channel };
                  }

                  if (chartDivLeft.current) {
                    let leftTop = sdk.createChart({
                      chartId: idList[0].chartID,
                      height: 400,
                      width: width,
                      theme: 'light',
                      filter: filterConfig,
                    });
                    setChartLeft(leftTop);
                    leftTop
                      .render(chartDivLeft.current)
                      .then(() => {
                        setRendered(true);
                        promiseArray.push({ chartName: idList[0].chartName, promise: leftTop.getData() });
                        setExcelData(promiseArray);
                      })
                      .catch((err) => console.log('Error during get data.', err));
                  }
                  if (idList[1] && chartDivRight.current) {
                    let rightTop = sdk.createChart({
                      chartId: idList[1].chartID,
                      height: 400,
                      width: width,
                      theme: 'light',
                      filter: filterConfig,
                    });
                    setChartRight(rightTop);
                    rightTop
                      .render(chartDivRight.current)
                      .then(() => {
                        setRendered(true);
                        promiseArray.push({ chartName: idList[1].chartName, promise: rightTop.getData() });
                        setExcelData(promiseArray);
                      })
                      .catch((err) => console.log('Error during get data.', err));
                  }
                  if (idList[2] && chartDivLeftMiddle.current) {
                    let leftMiddle = sdk.createChart({
                      chartId: idList[2].chartID,
                      height: 400,
                      width: width,
                      theme: 'light',
                      filter: filterConfig,
                    });
                    setChartLeftMiddle(leftMiddle);
                    leftMiddle
                      .render(chartDivLeftMiddle.current)
                      .then(() => {
                        setRendered(true);
                        promiseArray.push({ chartName: idList[2].chartName, promise: leftMiddle.getData() });
                        setExcelData(promiseArray);
                      })
                      .catch((err) => console.log('Error during get data.', err));
                  }
                  if (idList[3] && chartDivRightMiddle.current) {
                    let rightMiddle = sdk.createChart({
                      chartId: idList[3].chartID,
                      height: 400,
                      width: width,
                      theme: 'light',
                      filter: filterConfig,
                    });
                    setChartRightMiddle(rightMiddle);
                    rightMiddle
                      .render(chartDivRightMiddle.current)
                      .then(() => {
                        setRendered(true);
                        promiseArray.push({ chartName: idList[3].chartName, promise: rightMiddle.getData() });
                        setExcelData(promiseArray);
                        setMoreChartData({ promiseArray, idList, filterConfig });
                      })
                      .catch((err) => console.log('Error during get data.', err));
                  }
                });
              } else {
                filterConfig = { ...filterConfig, agentCode };
                type resultType = keyof typeof result;
                type fieldsType = keyof typeof fields;
                type dataType = keyof typeof data[0];

                const fields = result['fields' as resultType] as object;
                const data = result['documents' as resultType] as Array<{ group: string }>;

                let nameObj = {
                  leadSource: '',
                };

                type nameType = keyof typeof nameObj;

                // fields[fieldName as fieldsType] -> chart's field name
                for (const fieldName in fields) {
                  nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
                }

                let dataList: Record<string, any[] | any> = {
                  leadSource: [],
                  region: [],
                  channel: [],
                };

                // filter duplicate choices
                data.forEach((item) => {
                  for (const key in dataList) {
                    if (
                      !!item[nameObj[key as nameType] as dataType] &&
                      !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                    ) {
                      dataList[key].push(item[nameObj[key as nameType] as dataType]);
                    }
                  }
                });
                getDataList(dataList);
                if (chartDivLeft.current) {
                  let leftTop = sdk.createChart({
                    chartId: idList[0].chartID,
                    height: 400,
                    width: width,
                    theme: 'light',
                    filter: filterConfig,
                  });
                  setChartLeft(leftTop);
                  leftTop
                    .render(chartDivLeft.current)
                    .then(() => {
                      setRendered(true);
                      promiseArray.push({ chartName: idList[0].chartName, promise: leftTop.getData() });
                      setExcelData(promiseArray);
                    })
                    .catch((err) => console.log('Error during get data.', err));
                }
                if (idList[1] && chartDivRight.current) {
                  let rightTop = sdk.createChart({
                    chartId: idList[1].chartID,
                    height: 400,
                    width: width,
                    theme: 'light',
                    filter: filterConfig,
                  });
                  setChartRight(rightTop);
                  rightTop
                    .render(chartDivRight.current)
                    .then(() => {
                      setRendered(true);
                      promiseArray.push({ chartName: idList[1].chartName, promise: rightTop.getData() });
                      setExcelData(promiseArray);
                    })
                    .catch((err) => console.log('Error during get data.', err));
                }
                if (idList[2] && chartDivLeftMiddle.current) {
                  let leftMiddle = sdk.createChart({
                    chartId: idList[2].chartID,
                    height: 400,
                    width: width,
                    theme: 'light',
                    filter: filterConfig,
                  });
                  setChartLeftMiddle(leftMiddle);
                  leftMiddle
                    .render(chartDivLeftMiddle.current)
                    .then(() => {
                      setRendered(true);
                      promiseArray.push({ chartName: idList[2].chartName, promise: leftMiddle.getData() });
                      setExcelData(promiseArray);
                    })
                    .catch((err) => console.log('Error during get data.', err));
                }
                if (idList[3] && chartDivRightMiddle.current) {
                  let rightMiddle = sdk.createChart({
                    chartId: idList[3].chartID,
                    height: 400,
                    width: width,
                    theme: 'light',
                    filter: filterConfig,
                  });
                  setChartRightMiddle(rightMiddle);
                  rightMiddle
                    .render(chartDivRightMiddle.current)
                    .then(() => {
                      setRendered(true);
                      promiseArray.push({ chartName: idList[3].chartName, promise: rightMiddle.getData() });
                      setExcelData(promiseArray);
                      setMoreChartData({ promiseArray, idList, filterConfig });
                    })
                    .catch((err) => console.log('Error during get data.', err));
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => console.log('Error during data Chart rendering.', err));
    }
  };

  useEffect(() => {
    let width = (window.innerWidth - 40) / 2;

    if (chartDivLeftBottom.current && moreChartData.idList[4]) {
      let leftBottom = sdk.createChart({
        chartId: moreChartData.idList[4].chartID,
        height: 400,
        width: width,
        theme: 'light',
        filter: moreChartData.filterConfig,
      });
      setChartLeftBottom(leftBottom);
      leftBottom
        .render(chartDivLeftBottom.current)
        .then(() => {
          setRendered(true);
          moreChartData.promiseArray.push({
            chartName: moreChartData.idList[4].chartName,
            promise: leftBottom.getData(),
          });
          setExcelData(moreChartData.promiseArray);
        })
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivRightBottom.current && moreChartData.idList[5]) {
      let rightBottom = sdk.createChart({
        chartId: moreChartData.idList[5].chartID,
        height: 400,
        width: width,
        theme: 'light',
        filter: moreChartData.filterConfig,
      });
      setChartRightBottom(rightBottom);
      rightBottom
        .render(chartDivRightBottom.current)
        .then(() => {
          setRendered(true);
          moreChartData.promiseArray.push({
            chartName: moreChartData.idList[5].chartName,
            promise: rightBottom.getData(),
          });
          setExcelData(moreChartData.promiseArray);
        })
        .catch((err) => console.log('Error during get data.', err));
    }
  }, [moreChartData]);

  useEffect(() => {
    if (chartDiv.current && dataChartDiv.current) {
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log(res));
        })
        .catch((err) => console.log('Error during Charts rendering.', err));

      dataChart
        .render(dataChartDiv.current)
        .then(() => {
          dataChart.getData().then((result) => {
            type resultType = keyof typeof result;
            type fieldsType = keyof typeof fields;
            type dataType = keyof typeof data[0];

            const fields = result['fields' as resultType] as object;
            const data = result['documents' as resultType] as Array<{ group: string }>;

            let nameObj = {
              leadSource: '',
              unitCode: '',
              businessManagerName: '',
              consultantName: '',
              region: '',
              channel: '',
            };

            type nameType = keyof typeof nameObj;

            // fields[fieldName as fieldsType] -> chart's field name
            for (const fieldName in fields) {
              nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
            }

            let dataList: Record<string, any[] | any> = {
              leadSource: [],
              unitCode: [],
              businessManagerName: [],
              consultantName: [],
              region: [],
              channel: [],
            };

            // filter duplicate choices
            data.forEach((item) => {
              for (const key in dataList) {
                if (
                  !!item[nameObj[key as nameType] as dataType] &&
                  !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                ) {
                  dataList[key].push(item[nameObj[key as nameType] as dataType]);
                }
              }
            });
            getDataList(dataList);
          });
        })
        .catch((err) => console.log('Error during data Chart rendering.', err));
    } else {
      console.log('Error');
    }
  }, [chartDiv, chart, rendered]);

  useEffect(() => {
    let tmpFilter = myFilter;
    for (const name in filter) {
      type filterKey = keyof typeof filter;
      if (Object.keys(tmpFilter).includes(name)) {
        tmpFilter[name] =
          !!filter[name as filterKey] && filter[name as filterKey] != 'all' ? filter[name as filterKey] : undefined;
      }
    }
    setMyFilter(tmpFilter);
  }, [chart, filter, rendered]);

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));
    let chartFilter = BDMdashboardFilter.get(filter.chartName);

    // delete the no filterName
    for (const key in obj) {
      if (!chartFilter[key] && !['region', 'channel'].includes(key)) {
        delete obj[key];
      }
    }

    if (obj?.assignedDate) {
      // pls put the date type to be filtered here
      if (!!myFilter?.assignedDate[0] && !!myFilter?.assignedDate[1]) {
        const startMonth = new Date(myFilter.assignedDate[0]).getMonth() + 1;
        const formattedStartMonth = startMonth < 10 ? '0' + startMonth : startMonth;

        const startDate = new Date(myFilter.assignedDate[0]).getDate();
        const formattedStartDate = startDate < 10 ? '0' + startDate : startDate;

        const startDateTimeString = `${new Date(
          myFilter.assignedDate[0],
        ).getFullYear()}-${formattedStartMonth}-${formattedStartDate}T00:00:00Z`;

        const startDateTime = new Date(startDateTimeString.toString());

        const endMonth = new Date(myFilter.assignedDate[1]).getMonth() + 1;
        const formattedEndMonth = endMonth < 10 ? '0' + endMonth : endMonth;

        const endDate = new Date(myFilter.assignedDate[1]).getDate();
        const formattedEndDate = endDate < 10 ? '0' + endDate : endDate;

        const endDateTimeString = `${new Date(
          myFilter.assignedDate[1],
        ).getFullYear()}-${formattedEndMonth}-${formattedEndDate}T23:59:59Z`;

        const endDateTime = new Date(endDateTimeString.toString());
        obj.$and = [{ assignedDate: { $gte: startDateTime } }, { assignedDate: { $lte: endDateTime } }];
      }
      delete obj.assignedDate;
    }

    getMoreCharts(BDMdashboardGroupMap.get(filter.chartName) ?? [], filter.chartType, obj);
  }, [filter]);

  useEffect(() => {
    if (BDMdashboardGroupMap.get(filter.chartName)) {
      getMoreCharts(BDMdashboardGroupMap.get(filter.chartName) ?? [], filter.chartType, {
        region: region,
        channel: channel,
      });
    } else if (topDashboards.get(filter.chartName) && filter.chartType == 'Table') {
      reRender(
        sdk.createChart({
          chartId: chartID,
          height: 800,
          theme: 'light',
          filter: { $and: [{ region: region }, { channel: channel }] },
        }),
      );
      setIsShowChart(true);
      getTopCharts(topDashboards.get(filter.chartName));
    } else {
      reRender(
        sdk.createChart({
          chartId: chartID,
          height: 800,
          theme: 'light',
          filter: { $and: [{ region: region }, { channel: channel }] },
        }),
      );
      setIsShowChart(false);
    }
  }, [filter.chartName, filter.chartType, isShowChart]);

  useEffect(() => {
    if (chartDiv.current) {
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log(res));
        })
        .catch((err) => console.log('Error during get data.', err));
    } else {
      console.log('Error');
    }
  }, [filter.chartName, filter.chartType, rendered]);

  const fetchDownline = (agentCode: string): Promise<any> => {
    if (agentCode) {
      return fetchDownlineDivision(agentCode);
    }
    return new Promise<any>((resolve, reject) => {});
  };

  return (
    <>
      {BDMdashboardGroupMap.get(filter.chartName) ? (
        <>
          <div className="top" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
            <div className="chart" ref={chartDivLeft} />
            <div className="chart" ref={chartDivRight} style={{ marginLeft: 20 }} />
          </div>
          <div className="middle" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', height: 400 }}>
            <div className="chart" ref={chartDivLeftMiddle} />
            <div className="chart" ref={chartDivRightMiddle} style={{ marginLeft: 20 }} />
          </div>
          {moreChartData.idList[4] && (
            <div className="bottom" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
              <div className="chart" ref={chartDivLeftBottom} />
              {moreChartData.idList[5] && (
                <div className="chart" ref={chartDivRightBottom} style={{ marginLeft: 20 }} />
              )}
            </div>
          )}
        </>
      ) : isShowChart ? (
        <>
          <div
            className="top"
            style={
              topDashboards.get(filter.chartName)?.type == 'Among'
                ? { marginBottom: 20 }
                : { marginBottom: 20, display: 'flex', flexDirection: 'row' }
            }
          >
            <div className="chart" ref={chartDivLeft} />
            {topDashboards.get(filter.chartName)?.dashboardRight && (
              <div
                className="chart"
                ref={chartDivRight}
                style={topDashboards.get(filter.chartName)?.type == 'Among' ? { marginTop: 20 } : { marginLeft: 20 }}
              />
            )}
          </div>
          <div className="chart" ref={chartDiv} />
        </>
      ) : (
        <div className="chart" ref={chartDiv} />
      )}
      <div className="data-chart" ref={dataChartDiv} hidden />
    </>
  );
};

export default DashboardDetailPage;
