import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-brief.style';
import { useCampaignBrief } from './campaign-brief.hook';
import { CampaignStructureItem, RsvpStatus } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { PopoverSelector } from '../popover-selector/popover-selector.component';
import EditIcon from '@mui/icons-material/Edit';
import { CampaignStatusTagComponent } from './campaign-status-tag/campaign-status-tag.component';
import { Button } from 'src/app/common/components/button/button.component';

type ComponentProps = ParamsProps & {
  campaign: any;
  campaignTypeStructureData?: CampaignStructureItem;
  updateRSVP: (rsvpStatus: string) => void;
  isCampaignOwner: boolean;
  isRequiredRsvp: boolean;
};

export const CampaignBriefComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { campaign, campaignTypeStructureData, isCampaignOwner, isRequiredRsvp, updateRSVP } = props;
  const { disableRSVPEdit } = useCampaignBrief({ campaign });

  return (
    <Paper elevation={3} className={styles.papaer}>
      <div className={styles.contentBox}>
        <div className={styles.statusContainer}>
          <span className={styles.campaignStatus}>{Translation('agencyCampaign.list.screen.filter.status')}:</span>
          <span>
            {campaign.campaignStatus ? (
              <CampaignStatusTagComponent campaignStatus={campaign.campaignStatus} />
            ) : (
              Translation(`agencyCampaign.common.status.draft`)
            )}
          </span>
        </div>
        <Grid container>
          <Grid item xs={3}>
            <Box>
              <div className={styles.briefTitle}>{Translation('agencyCampaign.common.campaignType')}</div>
              <div className={styles.briefContent}>{campaignTypeStructureData?.name}</div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <div className={styles.briefTitle}>{Translation('agencyCampaign.common.campaignId')}</div>
              <div className={styles.briefContent}>{campaign.campaignId ? campaign.campaignId : `--`}</div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <div className={styles.briefTitle}>{Translation('agencyCampaign.common.leadSource')}</div>
              <div className={styles.briefContent}>{campaignTypeStructureData?.agentLeadSource ?? '-'}</div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <div className={styles.briefTitle}>{Translation('agencyCampaign.common.leadSubSource')}</div>
              <div className={styles.briefContent}>
                {campaignTypeStructureData?.agentLeadSubSource
                  ? campaignTypeStructureData.agentLeadSubSource.join(', ')
                  : '-'}
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      {isRequiredRsvp ? (
        <>
          <Divider />
          <div className={styles.rsvpBox}>
            <Box display={'flex'} flexDirection={'row'} flexGrow={0} alignItems={'center'}>
              {isCampaignOwner || !campaign.campaignOwner || campaign.rsvpStatus === RsvpStatus.Accepted ? (
                <>
                  <span className={styles.rsvpAcceptTitle}>{Translation('agencyCampaign.rsvp.invitation')}:</span>
                  <Typography className={styles.rsvpTextAccept}>
                    {Translation('agencyCampaign.rsvp.acceptedUpper')}
                  </Typography>
                </>
              ) : null}
              {!isCampaignOwner && campaign.rsvpStatus === RsvpStatus.Rejected ? (
                <>
                  <span className={styles.rsvpAcceptTitle}>{Translation('agencyCampaign.rsvp.invitation')}:</span>
                  <Typography className={styles.rsvpTextReject}>
                    {Translation('agencyCampaign.rsvp.rejectedUpper')}
                  </Typography>
                </>
              ) : null}
              {!isCampaignOwner && campaign.rsvpStatus === RsvpStatus.Pending && !disableRSVPEdit ? (
                <>
                  <span className={styles.rsvpNotAcceptTitle}>{Translation('agencyCampaign.rsvp.invitation')}</span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => updateRSVP(RsvpStatus.Rejected)}
                    className={styles.ml_24}
                  >
                    {Translation('agencyCampaign.rsvp.reject')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => updateRSVP(RsvpStatus.Accepted)}
                    className={styles.ml_19}
                  >
                    {Translation('agencyCampaign.create.inviteAccept')}
                  </Button>
                </>
              ) : null}
              {!isCampaignOwner && !disableRSVPEdit ? (
                <PopoverSelector
                  options={[
                    {
                      label: Translation('agencyCampaign.rsvp.accepted'),
                      value: 'Accepted',
                    },
                    {
                      label: Translation('agencyCampaign.rsvp.rejected'),
                      value: 'Rejected',
                    },
                  ]}
                  onChange={(val) => {
                    updateRSVP(String(val));
                  }}
                >
                  <EditIcon style={{ width: 24, height: 24, margin: 0, padding: 0 }} />
                </PopoverSelector>
              ) : null}
            </Box>
          </div>
        </>
      ) : null}
    </Paper>
  );
});
