import { TranslationWithParams } from 'src/app/i18n';
import { FollowUpStatusEnum, LeadLabel } from 'src/app/modules/Campaign/types/campaign-types';
import { styles } from './lead-column.style';
import React from 'react';
import { LeadOpportunityOperation } from '../../../../../../Leads/components/lead-list-item/lead-operation-units/lead-opportunity-operation/lead-opportunity-operation.component';
import { LeadFollowUpOperation } from '../../../../../../Leads/components/lead-list-item/lead-operation-units/lead-follow-up-operation/lead-follow-up-operation.component';
import { LeadStatusStepperComponent } from 'src/app/modules/Leads/components/lead-status-stepper/lead-status-stepper.component';
import { getLeadDeadReasonToDisplay } from 'src/app/modules/Leads/components/lead-list-item/lead-remark/util/remark.util';
interface IssuedPolicy {
  issuedDate: string;
  policyNumber: string;
}
export const renderStatus = (currValue: string, item: any, t: any) => {
  return <LeadStatusStepperComponent followUp={item.followUp} agentStatus={item.status} />;
};

export const renderRemark = (currValue: any[], item: any, t: any) => {
  const { reason, reasons, issuedPolicy } = item.followUp[item.followUp.length - 1] ?? {
    reason: '',
    reasons: [],
    issuedPolicy: [],
  };
  return item.followUpStatus === FollowUpStatusEnum.dead ? (
    <div>
      <div>{t('lead_remark_deadreason')}</div>
      {getLeadDeadReasonToDisplay(t, reasons, reason).map((r, index) => (
        <div key={index}>{r}</div>
      ))}
    </div>
  ) : issuedPolicy?.length ? (
    <div>
      {issuedPolicy?.map((v: IssuedPolicy, idx: number) => {
        return (
          <div key={idx}>
            {t('salesLdPolicyNumber')}:{v.policyNumber}
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export const renderOp = (currValue: string, item: any, action: any) => {
  if (item.status === FollowUpStatusEnum.pending) {
    return <LeadOpportunityOperation item={item} actions={action} />;
  } else if (
    ![FollowUpStatusEnum.dead, FollowUpStatusEnum.policy, FollowUpStatusEnum.contactFailure].includes(item.status)
  ) {
    return <LeadFollowUpOperation item={item} actions={action} />;
  } else return '';
};

export const leadLabelsTag = (labels: string[], t: (val: string) => string) => {
  const labelStyles: { [key in LeadLabel]?: { backgroundColor: string; color: string } } = {
    [LeadLabel.potentialCustomer]: styles.potentialCustomerColor,
    [LeadLabel.existingCustomer]: styles.existingCustomerColor,
    [LeadLabel.referralCode]: styles.referralCodeColor,
    [LeadLabel.redistributedLead]: styles.referralCodeColor,
    [LeadLabel.reassignLead]: styles.referralCodeColor,
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {labels.map((label, index) => {
        const style = labelStyles[label as LeadLabel] || styles.potentialCustomerColor;
        return (
          <div
            key={index}
            style={{
              ...style,
              ...styles.labelView,
            }}
          >
            {t(`campaign.lead.label.${label}`)}
          </div>
        );
      })}
    </div>
  );
};
