import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

export default function ContentFooter() {
  const { classes } = useStyles();
  const today = new Date().getFullYear();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.date}>{today.toString()}</Typography>
      &copy;{' '}
      <a href="https://www.prudential.com.hk/" target="_blank" rel="noopener noreferrer" className={classes.link}>
        Prudential Assurance Company Singapore
      </a>
    </div>
  );
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    whiteSpace: 'pre-wrap',
    padding: '13px 25px',
  },
  date: {
    color: '#B5B5C3',
    marginRight: 8,
  },
  link: {
    '&:hover': {
      color: '#3699ff',
    },
  },
}));
