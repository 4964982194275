import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getColumns } from './util/lead-column.util';
import {
  CampaignLeadItem,
  FollowUpStatusEnum,
  MassCampaignLeadItem,
} from 'src/app/modules/Campaign/types/campaign-types';
import { get } from 'lodash';
import { getAppConfigs, APP_CONFIG } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLeadList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { AgentStatusEnum } from 'src/app/modules/Leads/types/types';

interface HookProps {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}

export const useMassCampaignLeadList = ({ cacheParams }: HookProps) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const Translation = useCallback((id: string) => intl.formatMessage({ id }), [intl]);
  const enableMassAcceptLeads = get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.lead.enableMassAcceptLeads', true);
  const dispatch = useDispatch();
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
      }),
    [Translation],
  );
  const convertLeadData = (data: any): MassCampaignLeadItem[] => {
    const leads: MassCampaignLeadItem[] = [];
    data?.forEach((lead: any) => {
      leads.push({
        ...lead,
        _id: lead._id,
        labels: lead.labels,
        name: lead.prospectDetail.alias,
        status: FollowUpStatusEnum.pending,
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt,
        feedback: lead.feedback,
        campaign: lead.campaign,
      });
    });
    return leads;
  };

  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      let sortBy = 'updatedAt';
      let sortOrder = -1;
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        sortBy = key;
        sortOrder = val === 'desc' ? -1 : 1;
      }
      const data = {
        campaignId: id,
        agentCode: [globalStore.getAgentCode()],
        leadQuery: { agentStatus: AgentStatusEnum.pending },
        page: params.page + 1,
        pageSize: params.pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
      };
      const res = await getLeadList(data, dispatch);
      if (res) {
        const { docs, totalDocs } = res;
        const leads = convertLeadData(docs);
        return {
          success: true,
          data: leads,
          total: totalDocs ?? 0,
        };
      }
      return { success: false, data: [], total: 0 };
    },
    [],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {},
      getCheckboxProps: (row: CampaignLeadItem, rows: CampaignLeadItem[]) => {
        if (!enableMassAcceptLeads) {
          return {
            disabled: true,
          };
        }
        if (rows && rows.length > 0) {
          const r = rows[0];
          return {
            disabled: r.status !== row.status,
          };
        }
        return {
          disabled: false,
        };
      },
    }),
    [enableMassAcceptLeads],
  );

  return {
    getData,
    columns,
    rowSelection,
    params: {},
    enableMassAcceptLeads,
  };
};
