import React, { memo, useContext, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { FormMode } from 'src/app/common/utils';
import { AddApoointmentDialogComponent } from 'src/app/modules/Prospect/pages/appointment/add-apoointment-dialog/add-apoointment-dialog.component';
import { Lead } from 'src/app/modules/Leads/types/types';
import { OperationContext } from '../../util/operation-context.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
};

export const AppointmentButton: React.FC<ComponentProps> = memo(({ item, actions }: ComponentProps) => {
  const { t } = useTranslation();
  const [showApptDialog, setShowApptDialog] = useState(false);
  const { gaData } = useContext(OperationContext);
  const doAppointmentCreate = () => {
    setShowApptDialog(true);
    const ga = gaData?.newApp;
    ga && takeUIClickEvent(ga);
  };

  return (
    <>
      <ProTableOperationBtnComponent
        onClick={() => {
          doAppointmentCreate();
        }}
        label={t('app.button.listOperation.appt')}
      />
      <AddApoointmentDialogComponent
        open={showApptDialog}
        onClose={() => setShowApptDialog(false)}
        mode={FormMode.CREATE}
        lead={item}
        onSuccess={() => actions(['refresh'])}
      />
    </>
  );
});
