import React, { memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useAcceptOpportunityButton } from './accept-opportunity-button.hook';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { Accept } from '../../../lead-operation/components/accept/accept.component';
import { Lead } from 'src/app/modules/Leads/types/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { allGaDataClick, handleWithCareReminderGaDataClick } from 'src/app/common/ga/types/ga-click-lead';

type ComponentProps = {
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  item: Lead;
};

export const AcceptOpportunityButton: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const { item } = props;

  // custom-hook
  const { acceptLoading, toAccept, setToAccept, doAccept, doAcceptGA } = useAcceptOpportunityButton(props);
  return (
    <>
      <ProTableOperationBtnComponent
        loading={acceptLoading}
        onClick={() => {
          setToAccept(true);
          doAcceptGA();
        }}
        label={t('app.button.accept')}
      />
      {toAccept ? (
        <Accept
          showDialog={item.leadWithPartialInfoDisplay}
          title={item.campaign?.leadWithPartialInfoWarning?.title}
          body={item.campaign?.leadWithPartialInfoWarning?.body}
          tnc={item.campaign?.leadWithPartialInfoWarning?.tnc}
          onAccept={async () => {
            await doAccept();
            takeUIClickEvent(handleWithCareReminderGaDataClick);
          }}
          onClose={() => setToAccept(false)}
        />
      ) : null}
    </>
  );
});
