import React, { FC, PropsWithChildren, createContext, useMemo,useState,useEffect } from 'react';
import { ModuleHierachy } from './types';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { surveySubmitModuleConfig } from '../../modules/Survey/module-def';
import { landingModuleConfig } from '../../modules/Landing/module-def';
// import { agencyCampaignModuleConfig } from '../../modules/AgencyCampaign/module-def';
// import { LitmosModuleConfig } from '../../modules/Litmos/module-def';
import { KpiDashboardModuleConfig } from '../../modules/KpiDashboard/module-def';
import { reviewWebFormModuleConfig } from 'src/app/modules/ReviewWebForm/module-def';
import { GlobalHelper } from '../helpers/GlobalHelpers';
import { SummaryDashboardModuleConfig } from 'src/app/modules/SummaryDashboard/module-def';
import { fetchAlAgentList } from '../../modules/KpiDashboard/network/agentCrud';
import { agentProfileModuleConfig } from 'src/app/modules/AgentProfile/module-def';
import { managerDashboardModuleConfig } from 'src/app/modules/ManagerDashboard/module-def';
import { useJWT } from '../utils/hook-utils';
import { prospectModuleConfig } from 'src/app/modules/Prospect/module-def';
import { campaignModuleConfig } from 'src/app/modules/Campaign/module-def';
import { leadsModuleConfig } from 'src/app/modules/Leads/module-def';
import { insightModuleConfig } from 'src/app/modules/Insight/module-def';
import { eventModuleConfig } from 'src/app/modules/event-v2/module-def';

type ModuleContextInternal = {
  moduleMap: ModuleHierachy[];
};

export const ModuleContext = createContext<ModuleContextInternal>({
  moduleMap: [],
});

const ModuleProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  GlobalHelper.setGlobalIntlRef(intl);

  const [downlineCount, setCounts] = useState<any>('');

  useEffect(() => {
    fetchAlAgentList().then((results) => {
      let allAgentsList: any = results;
      setCounts(allAgentsList.length);
    });
  }, [downlineCount]);

  const token = useJWT();
  const moduleMap: ModuleHierachy[] = useMemo(() => {
    if (!token) {
      return [];
    }
    const permissions = map(token.permissions, (_, key) => key);

    return [
      //  Export module configurations here
      leadsModuleConfig(permissions, intl),
      prospectModuleConfig(permissions, intl),
      campaignModuleConfig(permissions, intl),
      // insightModuleConfig(permissions, intl), // PACS Customization: PACSPFAP-2722 | Phase-1 | Hide Insight Module
      landingModuleConfig(permissions, intl),
      surveySubmitModuleConfig(permissions, intl),
      KpiDashboardModuleConfig(token, permissions, intl, downlineCount),
      // agencyCampaignModuleConfig(permissions, intl),
      // PCAAEB-46075: comment on 24.07 for surveyjs upgrade
      // reviewWebFormModuleConfig(permissions, intl),
      SummaryDashboardModuleConfig(permissions, intl),
      agentProfileModuleConfig(permissions, intl),
      managerDashboardModuleConfig(permissions, intl),
      // eventModuleConfig(permissions, intl), // PACS Customization: PACSPFAP-2722 | Phase-1 | Hide Event Module
      // Litmos needs to be the last module
      // LitmosModuleConfig(permissions, intl),

      // eslint-disable-next-line
    ];
    // eslint-disable-next-line
  }, [downlineCount, token]);

  return <ModuleContext.Provider value={{ moduleMap }}>{children}</ModuleContext.Provider>;
};

export default ModuleProvider;
