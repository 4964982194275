import React, { memo } from 'react';
import { RejectOpportunityButton } from '../components/reject-opportunity-button/reject-opportunity-button.component';
import { AcceptOpportunityButton } from '../components/accept-opportunity-button/accept-opportunity-button.component';
import { Lead } from 'src/app/modules/Leads/types/types';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
};

export const LeadOpportunityOperation: React.FC<ComponentProps> = memo(({ item, actions }: ComponentProps) => {
  return (
    <>
      <RejectOpportunityButton item={item} actions={actions} />
      <AcceptOpportunityButton item={item} actions={actions} />
    </>
  );
});
