import React, { useState, useEffect, ComponentType, useCallback, useRef, ReactNode } from 'react';
import Table from './ProTable';
import ProFilter, { FilterProps, FilterState } from './ProFilter';
import { useDataProvider } from './hook';
import { SxProps, Theme } from '@mui/material';
import { ListFilterProps } from '../list-filter/list-filter.component';
import { useDispatch } from 'react-redux';

export interface ProCoreActionType {
  /**
   * @name refresh
   */
  reload: (resetPageIndex?: boolean) => void;
  /**
   * @name refresh and clear
   */
  // reloadAndRest?: () => void;
  // /**
  //  * @name reset
  //  */
  // reset?: () => void;

  /**
   * @name clearFilter
   */
  clearSelected?: () => void;
  setSelectedRow?: React.Dispatch<React.SetStateAction<any[]>>;
}

export type ActionType = ProCoreActionType;

export type ParamsType = {
  [key: string]: string | string[];
};

type ProSchemaValueEnumType = {
  text: React.ReactNode;
  status: string;
};

export type ProSchemaValueEnumObj = {
  [key: string]: ProSchemaValueEnumType;
};

export interface FilterDropdownParams {
  // selectedKeys: ,
  confirm?: () => void;
  clearFilters?: () => void;
}

export interface ProColumns<T> {
  key?: string;
  // dataIndex?: string | number | (string | number)[];
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  dataIndex: string;
  valueType?: string;
  title: React.ReactNode;
  render?: (dom: any, entity: T, actions: (actions: ('clearRow' | 'refresh')[]) => void) => React.ReactNode;
  valueEnum?: ProSchemaValueEnumObj;
  hideInForm?: boolean;
  hideInSearch?: boolean;
  sorter?: boolean;
  request?: () => Promise<{ label: string; value: string; key: number }[]>;
  filterDropdown?: (params: FilterDropdownParams) => React.ReactNode;
  sticky?: boolean;
  // width?: number | string;
  width: '290px' | '176px' | '466px' | '78px' | '39px';
}

export interface ProOperationDef<T> {
  title: string | JSX.Element;
  disabled?: (row: T) => boolean;
  hidden?: (row: T) => boolean;
  onClick: (row: T, rowIndex: number) => void;
}

export interface RequestData<T> {
  data: T[];
  success?: boolean;
  total?: number;
  [key: string]: any;
}

export type RequestFunction<T, U> = (
  params: U & {
    page: number;
    pageSize: number;
    current?: number;
    keyword?: string;
  },
  sort: {
    [key: string]: any;
  },
  filter: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
) => Promise<RequestData<T>>;

export type ToolBarRender = () => React.ReactNode[];
export type HeaderTitle = React.ReactNode;

export type RowSelection<T> = {
  onChange: (selectedRows: T[]) => void;
  getCheckboxProps: (
    record: T,
    selectedRows: T[],
  ) => {
    disabled?: boolean;
  };
};

export interface ActionNodeTypeProps<T> {
  selectedRows: T[];
  onAction: (action: ('clearRow' | 'refresh')[]) => void;
}

export type ActionNodeType<T> = React.ComponentType<ActionNodeTypeProps<T>>;

export enum CheckType {
  checkbox,
  radio,
}

export interface ProTableProps<T, U extends ParamsType> {
  columns: ProColumns<T>[];
  operationDef?: ProOperationDef<T>[];
  operationSticky?: boolean;
  request: RequestFunction<T, U>;
  toolBarRender?: ToolBarRender;
  filterTitle?: string;
  headerTitle?: HeaderTitle;
  rowKey: string;
  actionRef?: React.MutableRefObject<ProCoreActionType | undefined>;
  rowSelection?: RowSelection<T>;
  hideReset?: boolean;
  hidePagination?: boolean;
  onLoad?: (
    record: T[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
  showFilter?: boolean;
  showFilterIcon?: boolean;
  Filter?: ComponentType<FilterProps<any>>;
  enableRefresh?: boolean;
  filterSx?: SxProps<Theme>;
  filterState?: FilterState;
  onFilterChange?: (filterState: FilterState) => void;
  formatText?: (options: { numSelected?: number; total: number }) => string;
  ActionNode?: ActionNodeType<T>;
  showActionBar?: boolean;
  filterConfigs?: ListFilterProps['filterConfigs'];
  showSelect?: boolean;
  defaultSelectedRows?: T[];
  onClickRow?: (row: T) => void;
  filterPlaceholder?: string;
  checkType?: CheckType;
  emptyText?: string;
  customEmptyTitle?: JSX.Element;
  formatTitle?: (title: string) => string | ReactNode;
  onFocus?: (val: any) => void;
  onFilter?: (val: any) => void;
  onSort?: (val: any) => void;
}

// interface PageConfig {
//   page: number;
//   pageSize: number;
// }

// interface FilterParams extends PageConfig,

// const ProTable: FC<ProTableProps<T>> = ({ history }) => {
const ProFilterTable = <
  T extends { [key: string]: any } = { [key: string]: any },
  U extends { [key: string]: any } = { [key: string]: any },
>({
  request,
  columns,
  operationDef,
  operationSticky,
  filterTitle,
  headerTitle,
  hideReset,
  toolBarRender,
  rowKey,
  actionRef,
  rowSelection,
  hidePagination,
  onLoad,
  showFilter = true,
  showFilterIcon = true,
  Filter = ProFilter,
  enableRefresh,
  filterSx,
  filterState: defaultState,
  onFilterChange,
  showActionBar = true,
  formatText,
  ActionNode,
  filterConfigs,
  showSelect,
  defaultSelectedRows,
  onClickRow,
  filterPlaceholder,
  checkType = CheckType.checkbox,
  emptyText,
  customEmptyTitle,
  formatTitle,
  onFocus,
  onSort,
  onFilter,
}: ProTableProps<T, U>) => {
  const dispatch = useDispatch();
  const tableRef = useRef<any>();

  const { sorter: defaultSorter, filter: defaultExtraFilter, filterState: defaultFilterState } = defaultState ?? {};
  const [filterState, setFilterState] = useState<any>(() => {
    return hidePagination
      ? {
          ...defaultFilterState,
        }
      : {
          page: 0,
          pageSize: 50,
          ...defaultFilterState,
        };
  });
  const [filterSavedState, setFilterSavedState] = useState<any>(
    hidePagination
      ? {
          ...defaultFilterState,
        }
      : {
          page: 0,
          pageSize: 50,
          ...defaultFilterState,
        },
  );
  const [sorter, setSorterState] = useState<{ [key: string]: 'asc' | 'desc' }>(defaultSorter ?? {});
  const [extraFilter, setExtraFilter] = useState<Record<string, any>>(defaultExtraFilter ?? {});

  const setSorter = useCallback(
    (state: { [key: string]: 'asc' | 'desc' }) => {
      onFilterChange?.({
        filterState: filterSavedState,
        sorter: state,
        filter: extraFilter,
      });
      setSorterState(state);
    },
    [onFilterChange, filterSavedState, extraFilter],
  );

  const onExtraFilterChange = useCallback(
    (state: Record<string, any>) => {
      const filterState = {
        ...filterSavedState,
        page: 0,
      };
      setFilterState({ ...filterState });
      setFilterSavedState({ ...filterState });
      onFilterChange?.({
        filterState: { ...filterState },
        sorter,
        filter: state,
      });
      setExtraFilter(state);
    },
    [onFilterChange, filterSavedState, sorter],
  );

  const { loading, reload, dataSource, total } = useDataProvider<RequestData<T>, T>(async () => {
    return await request(filterSavedState, sorter, extraFilter, dispatch);
  }, onLoad);

  // query data after reset
  // const resetPageIndex = () => {
  //   setFilterState((state: any) => ({
  //     ...state,
  //     page: 0,
  //     pageSize: 20
  //   }))
  // }

  // useUpdateEffect(() => {
  //   if (page !== filterState.page) {
  //     setFilterSavedState({
  //       ...filterState,
  //       page
  //     })
  //     setFilterState({
  //       ...filterState,
  //       page
  //     })
  //   }
  // // eslint-disable-next-line
  // }, [page])

  useEffect(() => {
    if (actionRef)
      actionRef.current = {
        reload,
        clearSelected: tableRef?.current?.clearRow,
        setSelectedRow: tableRef?.current?.setSelectedRow,
      };
    reload();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showFilter ? (
        <Filter
          placeholder={filterPlaceholder}
          title={filterTitle}
          columns={columns}
          filterState={filterState}
          setFilterState={setFilterState}
          onFocus={onFocus}
          // onChangeFilter={data => setFilterState({
          //   ...filterState,
          //   ...data
          // })}
          onSave={(data) => {
            setFilterState({
              ...data,
              page: 0,
            });
            setFilterSavedState({
              ...data,
              page: 0,
            });
            onFilterChange?.({
              filterState: {
                ...data,
                page: 0,
              },
              sorter,
              filter: extraFilter,
            });
            reload();
          }}
          hideReset={hideReset}
          total={total}
          loading={loading}
          showFilter={showFilterIcon}
          sx={filterSx}
          extraFilter={extraFilter}
          onExtraFilterChange={(state) => {
            onExtraFilterChange(state);
            reload();
            onFilter?.(state);
          }}
          filterConfigs={filterConfigs}
        />
      ) : null}
      <Table<T>
        ref={tableRef}
        checkType={checkType}
        onClickRow={onClickRow}
        defaultSelectedRows={defaultSelectedRows}
        showSelect={showSelect}
        sorterDirection={sorter}
        formatText={formatText}
        showActionBar={showActionBar}
        ActionNode={ActionNode}
        enableRefresh={enableRefresh}
        hidePagination={hidePagination}
        rowSelection={rowSelection}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        operationDef={operationDef}
        operationSticky={operationSticky}
        onRefresh={() => {
          setFilterState(filterSavedState);
          reload();
        }}
        page={filterSavedState.page}
        pageSize={filterSavedState.pageSize}
        total={total}
        toolBarRender={toolBarRender}
        headerTitle={headerTitle}
        rowKey={rowKey}
        onChangePage={(params) => {
          setFilterSavedState({
            ...filterSavedState,
            ...params,
          });
          setFilterState({
            ...filterSavedState,
            ...params,
          });
          onFilterChange?.({
            filterState: {
              ...filterSavedState,
              ...params,
            },
            sorter,
            filter: extraFilter,
          });

          reload();
        }}
        handleSort={(newSorter) => {
          // only support latest sorter; only support one sorter.
          // currently requirement just need to sort by one field.
          const newDiffSort: { [key: string]: 'asc' | 'desc' } = {};
          Object.entries(newSorter).some(([key, value]) => {
            if (sorter[key] !== newSorter[key]) {
              newDiffSort[key] = value;
              return true;
            }
            return false;
          });
          setSorter(newDiffSort);
          reload();
          onSort?.(newDiffSort);
        }}
        emptyText={emptyText}
        customEmptyTitle={customEmptyTitle}
        formatTitle={formatTitle}
      />
    </>
  );
};

export default ProFilterTable;
