import { I18nGenericData } from 'src/app/i18n';

export type DesignationType = 'SRD' | 'RD' | 'SRM' | 'RM' | 'BM' | 'SUM' | 'UM' | 'SBSM' | 'BSM' | 'SFC' | 'FC' | 'LA';

export const DesignationValueMap = {
  SRD: 'G5',
  RD: 'G4',
  SRM: 'G3',
  RM: 'G2',
  BM: 'G1L3',
  SUM: 'G1L2',
  UM: 'G1L1',
  SBSM: 'SIC2',
  BSM: 'SIC1',
  SFC: 'SLU',
  FC: 'LU',
  LA: 'LA',
};

export type AgentDesignation = Record<string, Record<string, string>>;

export enum LanguageType {
  EN = 'enUs',
  ZH_HK = 'zhHk',
}

export type PaginateList<T extends object> = {
  total: number;
  totalPages: number;
  limit: number;
  items: T[];
  data?: T[];
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalDocs: number;
};

export enum RedirectType {
  APPLICATION_DOWNLOAD = 'download-page',
}

export const redirectMap: Record<RedirectType, string> = {
  [RedirectType.APPLICATION_DOWNLOAD]: '/application/download',
};

export type ValueOf<T> = T[keyof T & string];

export type SortKey = {
  key: string;
  value?: string;
};

interface Address {
  enUs: {
    address1: string;
    address2: string;
  };
}

interface Name {
  enUs: {
    displayName: string;
    surname: string;
    firstName: string;
    otherName: string;
    nickName: string;
  };
}

interface DesignationName {
  enUs: string;
  role: string;
}

interface Designation {
  code: string;
  name: DesignationName;
}

interface DisplayName {
  enUs: string;
}

interface DistributionChannel {
  channel: string;
  subChannel: string;
  description: string;
}

interface Division {
  code: string;
  description: string;
}

interface License {
  gi: string;
  hkfi: string;
  mpf: string;
  sfc: string;
}

interface Phone {
  general: string;
  direct: string;
  mobile: string;
}

interface UplineAgentName {
  enUs: null;
}

interface UplineAgent {
  code: string;
  name: UplineAgentName;
}

interface ActiveProfilePicture {
  owner: string;
  blobId: string;
  expiresOn: string;
  url: string;
  mimeType: string;
}

interface ProfilePicture {
  active: ActiveProfilePicture;
  pending: null;
}

export interface Agent {
  role: string;
  agentCode: string;
  address: Address;
  agency: string;
  branch: string;
  createdAt: string;
  designation: Designation;
  displayName: DisplayName;
  distributionChannel: DistributionChannel;
  division: Division;
  email: string;
  joinDate: string;
  license: License;
  luDate: string;
  name: Name;
  phone: Phone;
  status: string;
  unit: string;
  updatedAt: string;
  uplineAgent: UplineAgent;
  zone: string;
  profilePicture: ProfilePicture;
  PRULeadsAgent: string;
  PRULeadsTraining: string;
  agencyName: string;
  agentGroup: null;
  allowCreateProspects: boolean;
  allowEngagments: null;
  allowLeaderCoaching: null;
  award: string;
  city: string;
  dob: string;
  gender: string;
  isActive: boolean;
  misconduct: null;
  officeCode: string;
  pref_lang: string;
  productLicenses: string;
  productTraining: string;
  race: string;
  yearOfExperience: string;
  channel: string;
  region: string;
  codeActivationDate: null;
}
export enum AgentRole {
  CANDIDATE = 'CANDIDATE',
  'PENDING_CANDIDATE' = 'PENDING_CANDIDATE',
  SECRETARY = 'SECRETARY',
  STAFF = 'STAFF',
  AGENT = 'AGENT',
  MANAGER = 'MANAGER',
}

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export type AttachmentDef = {
  blobId: string;
  filename: string;
  url?: string;
  type?: string;
};

export type DesignationItem = {
  _id: string;
  code: string;
  departmentCode: string;
  level: number;
  description: I18nGenericData<string>;
  shortName: string;
  effectiveDate: Date;
};
