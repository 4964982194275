import { Dispatch } from 'react';
import { AGENT_URL, MANAGER_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { CampaignItem } from '../../AgencyCampaign/types/campaign-types';
import {
  AgentCampaignList,
  AgentSourceList,
  CampaignPaginateList,
  FollowUpList,
  FollowUpQueryParams,
  GetLeadsByGroupReqDto,
  OpportunityList,
  OpportunityQueryParams,
  SourceList,
  PoliciesResponseModel,
  RequestProspectLead,
  ReassignLeadsRequest,
  ProspectLeadParams,
  CommonLeadList,
  AutoDropFollowList,
  AutoDropLeadParams,
} from '../types/types';
import { stringify } from 'query-string';
import { forEach } from 'lodash';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { trim } from 'lodash';
import { ProspectDetail } from '../../Prospect/types/prospect-types';
import { DateToTs } from '../../Prospect/util/common.util';

export const getCampaigns = async (
  params: Record<string, any>,
  dispatch: Dispatch<any>,
): Promise<CampaignPaginateList<CampaignItem>> => {
  const path = `${AGENT_URL}/agents/me/campaigns/list/types`;
  return apiClient
    .get(path, { params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//lead source
export const getLeadSource = async (campaignId: string, dispatch?: Dispatch<any>) => {
  let apiString;
  if (campaignId === '') {
    //Normal call
    apiString = '/leads/dropdown?type=agentLeadSource';
  } else {
    //Call with Campaign ID
    apiString = '/leads/dropdown?type=leadSource&campaign=' + campaignId;
  }

  const queryPath = `${AGENT_URL}/agents/me` + apiString;

  return apiClient
    .get<any>(`${queryPath}`)
    .then((response) => {
      let rawData;
      if (campaignId === '') {
        rawData = response.data.agentLeadSource;
      } else {
        rawData = response.data.leadSource;
      }
      return rawData;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getOpportunityList = async (
  params: Omit<OpportunityQueryParams, 'categories'>,
  dispatch?: Dispatch<any>,
): Promise<OpportunityList> => {
  const queryStr = stringify(params, { arrayFormat: 'bracket' });
  const path = `${AGENT_URL}/agents/me/leads/onboard?categories=SalesLead&categories=SalesLead&${queryStr}`;
  return apiClient
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getFollowUpList = async (
  params: Omit<FollowUpQueryParams, 'categories'>,
  dispatch?: Dispatch<any>,
): Promise<FollowUpList> => {
  const queryStr = stringify(params, { arrayFormat: 'bracket' });
  const path = `${AGENT_URL}/agents/me/leads/follow-up?categories=SalesLead&categories=SalesLead&${queryStr}`;
  return apiClient
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getClosedList = async (
  params: Omit<FollowUpQueryParams, 'categories'>,
  dispatch?: Dispatch<any>,
): Promise<FollowUpList> => {
  const queryStr = stringify(params, { arrayFormat: 'bracket' });
  const path = `${AGENT_URL}/agents/me/leads/closed?categories=SalesLead&categories=SalesLead&${queryStr}`;
  return apiClient
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getSourceList = async (params: { tab: string }, dispatch?: Dispatch<any>): Promise<SourceList[]> => {
  const path = `${AGENT_URL}/agents/me/leads/search-source-list`;
  return apiClient
    .get(path, { params: { page: 1, ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLeadSubSource = async (payload: { isCampaign: boolean; value: string }, dispatch?: Dispatch<any>) => {
  const { isCampaign, value } = payload;
  let apiString;
  if (isCampaign) {
    apiString = '/leads/dropdown?type=leadSubSource&campaign=' + value;
  } else {
    apiString = '/leads/dropdown?type=agentLeadSource&parent=' + value;
  }

  const queryPath = `${AGENT_URL}/agents/me` + apiString;

  return apiClient
    .get<any>(`${queryPath}`)
    .then((response) => {
      let rawData;
      if (isCampaign) {
        rawData = response.data.leadSubSource;
      } else {
        rawData = response.data.agentLeadSource;
      }
      return rawData;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const getLeadById = async (id: string, dispatch: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/${id}`;
  return apiClient
    .get(path)
    .then((response) => {
      const rawData = {
        ...response.data,
        id: response.data._id,
        leadQualification: response.data.qualification ?? response.data.leadRaw?.leadQualification,
      };
      return rawData;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getCampaignList = async (
  params: { tab: string },
  dispatch?: Dispatch<any>,
): Promise<AgentCampaignList[]> => {
  const path = `${AGENT_URL}/agents/me/campaigns/list`;
  return apiClient
    .get(path, { params: { page: 1, ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const fetchClientList = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  const convertClientsFromPolicies = (rawData: PoliciesResponseModel[]) => {
    const resultsMap: any = {};
    for (let index = 0; index < rawData.length; index++) {
      const policy = rawData[index];
      if (policy?.polOwnerCode && policy?.polOwnerName && policy?.policyNo) {
        resultsMap[policy.polOwnerCode] = resultsMap[policy.polOwnerCode] || {
          clientName: policy.polOwnerName.toString().trim(),
          clientCode: policy.polOwnerCode.toString().trim(),
        };
        resultsMap[policy.polOwnerCode].policies = resultsMap[policy.polOwnerCode].policies || [];
        resultsMap[policy.polOwnerCode].policies.push(policy.policyNo.toString());
      }
    }
    const results = [];
    for (const key in resultsMap) {
      if (Object.prototype.hasOwnProperty.call(resultsMap, key)) {
        const client = resultsMap[key];
        results.push(client);
      }
    }
    return results.sort((a, b) => {
      return a.clientCode > b.clientCode ? 1 : -1;
    });
  };
  let path = `${AGENT_URL}/agents/me/policies?`;
  forEach(params, (param, key) => {
    path += `${key}=${param ? encodeURI(param) : ''}&`;
  });

  if (path.slice(-1) === '&') {
    // Remove additional substring
    path = path.substring(0, path.length - 1);
  }

  return apiClient
    .get<any>(path)
    .then((response) => {
      return convertClientsFromPolicies(response.data);
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAgentSourceList = async (dispatch?: Dispatch<any>): Promise<AgentSourceList[]> => {
  const path = `${AGENT_URL}/agents/me/agent-lead-source`;
  return apiClient
    .get(path, { params: { page: 1 } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const postProspectLead = async (lead: RequestProspectLead, dispatch?: Dispatch<any>): Promise<any> => {
  // make the api call
  // const response: Response = await this.api.post(`${this.config.url}/invitations/${GlobalHelper.getStaffSelectedAgentCode() || 'me'}`, {
  let response: any;
  const path = `${AGENT_URL}/agents/me/leads`;
  return apiClient
    .post<any>(path, lead)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const postAcceptLeads = async (
  data: {
    isRefuseToReceiveLeads: boolean;
  },
  dispatch?: Dispatch<any>,
): Promise<AgentSourceList[]> => {
  const path = `${AGENT_URL}/agents/me/leads/optToggle`;
  return apiClient
    .post(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export type PatchLeadData = {
  agentStatus: string;
  rejectedReason: string | null;
};

export type PatchMultiLeadsData = PatchLeadData & {
  ids: string[];
};

export const patchOpportunity = async (id: string, data: PatchLeadData, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/${id}/agent-status`;
  return apiClient
    .patch(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const patchOpportunities = async (data: PatchMultiLeadsData, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/agent-status`;
  return apiClient
    .patch(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export interface issuedPolicy {
  policyNumber: string;
  issuedDate?: Date | null;
}
export interface PostFollowUpReq {
  status: string;
  submissionDate: string;
  reason?: string;
  reasons?: (string | number)[];
  policyNumber?: string;
  issuedPolicy?: (issuedPolicy | null)[];
}

export const patchFollowUp = async (id: string, data: PostFollowUpReq, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/${id}/follow-up/status`;
  return apiClient
    .patch(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getDownline = async (dispatch?: Dispatch<any>): Promise<any> => {
  // const host = `http://localhost:3000`;
  const agentCode = globalStore.getAgentCode();
  const path = `${MANAGER_URL}/managers/${agentCode}/downlines/leads-count`;
  return apiClient
    .get<any>(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLeadsByGroupCode = async (params: GetLeadsByGroupReqDto, dispatch: Dispatch<any>): Promise<any> => {
  const agentCode = globalStore.getAgentCode();
  const path = `${MANAGER_URL}/managers/${agentCode}/downlines/leads`;
  return apiClient
    .get<any>(path, { params: { ...params } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAgentCodeListByGroup = async (
  data: { agentCode: string; groupCode: string },
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const { agentCode, groupCode } = data;
  const path = `${MANAGER_URL}/managers/${agentCode}/downlines/profiles/${groupCode}`;
  return apiClient
    .get<any>(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const reasignLead = async (data: ReassignLeadsRequest, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/downlines/reassign-leads`;
  return apiClient
    .post(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getReassignLeadCount = async (dispatch: Dispatch<any>): Promise<any> => {
  const agentCode = globalStore.getAgentCode();
  const path = `${MANAGER_URL}/managers/${agentCode}/downlines/leads-count`;
  return apiClient
    .get<any>(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const patchLeadById = async (body: any, dispatch: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/leads/${body.id}/update`;
  return apiClient.patch(path, body).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const deleteAgentCreateLead = async (id: string, dispatch: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/${id}`;
  return apiClient.delete(path).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getProspectLead = async (
  params: Omit<ProspectLeadParams, 'tab'>,
  dispatch?: Dispatch<any>,
): Promise<CommonLeadList> => {
  const queryStr = stringify(params, { arrayFormat: 'bracket' });
  const path = `${AGENT_URL}/agents/me/leads?${queryStr}`;
  return apiClient
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const getAllFollowUpList = async (dispatch: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/follow-up`;
  return apiClient.get<any>(path).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getNewOpportunitiesList = async (dispatch: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/onboard`;
  return apiClient.get<any>(path).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getLeadStatusDetailList = async (data: any, dispatch: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/leads-status/detail-list`;
  return apiClient
    .get<any>(path, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAutoDropLeadList = async (
  params: AutoDropLeadParams,
  dispatch?: Dispatch<any>,
): Promise<AutoDropFollowList> => {
  const queryStr = stringify(params, { arrayFormat: 'bracket' });
  const path = `${AGENT_URL}/agents/me/leads/auto-drop-leads?${queryStr}`;
  return apiClient
    .get<any>(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
