import React, { memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './purge-prospect-reminder.style';
import { usePurgeProspectReminder } from './purge-prospect-reminder.hook';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from 'react-router-dom';
import { AUTO_PURGE_LIST, PROSPECT_BASE_PATH } from '../../../constants';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { prospectListGaDataClick } from 'src/app/common/ga/types/ga-click';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
};

export const PurgeProspectReminder: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { t: Translation } = useTranslation();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { showReminder, total, notifyDays } = usePurgeProspectReminder();

  return showReminder ? (
    <Box className={styles.container}>
      <Box className={styles.textIcon}>
        <WarningAmberIcon className={styles.icon} />
        <Typography className={styles.text}>
          {Translation('pruleads.prospect.purge.reminder', { num: total, day: notifyDays })}
        </Typography>
      </Box>
      <Link
        onClick={() => {
          takeUIClickEvent(prospectListGaDataClick as any);
        }}
        to={`${PROSPECT_BASE_PATH}${AUTO_PURGE_LIST}`}
      >
        <Typography className={styles.checkText}>{Translation('pruleads.prospect.purge.check')}</Typography>
      </Link>
    </Box>
  ) : null;
});
