import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './detail-page.style';
import { useDetailPage, ServicingCampaignContext } from './detail-page.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { CampaignDetailViewComponent } from '../../../components/campaign-detail-view/campaign-detail-view.component';
import { ServicingCampaignLeadListComponent } from './servicing-campaign-lead-list/campaign-lead-list.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const DetailPagePage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { id } = useParams<any>();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { loading, data, run, navToList, leadsData, activityStatusFeedback, refresh } = useDetailPage({ id: id });

  return (
    <ServicingCampaignContext.Provider value={{ activityStatusFeedback, refresh }}>
      <Page>
        <HeaderBreadCrumbs />
        <PageTitle
          title={Translation('campaign.detail_title')}
          rightNode={
            // todo: button style
            <Button variant="outlined" color="inherit" onClick={navToList}>
              {Translation('app.button.back')}
            </Button>
          }
        />
        {loading ? (
          <LayoutSplashScreen />
        ) : (
          <div>
            <CampaignDetailViewComponent campaignDetail={data} />
            <div className="tw-mt-5">
              <ServicingCampaignLeadListComponent data={leadsData} campaignId={id} />
            </div>
          </div>
        )}
      </Page>
    </ServicingCampaignContext.Provider>
  );
};
