import React, { memo, useEffect, useMemo, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-lead-list.style';
import { useCampaignLeadList } from './campaign-lead-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { CampaignDetail, CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionItems } from './action-items/action-items.component';
import { useSelector } from 'react-redux';
import { SelectDownline } from 'src/app/common/components/select-downline/select-downline.component';
import { CategorySelectorComponent } from 'src/app/common/components/category-selector/category-selector.component';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { Option } from 'src/app/common/components/cascading-selector/cascading-selector.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { Typography } from '@mui/material';
import { Translation } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: CampaignDetail;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
};

const TabLeadFilterComponent: React.FC<any> = memo(
  ({
    cacheParams,
    campaignId,
    agentCode,
    filterState,
    setFilterState,
    onExtraFilterChange,
    onSave,
    setDisabledOp,
  }: any) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const [agentCodes, setAgentCodes] = React.useState<string[]>([agentCode]);
    useEffect(() => {
      if (filterState.agentCodes) {
        setAgentCodes(filterState.agentCodes);
      }
    }, []);
    useEffect(() => {
      if (agentCodes[0] === globalStore.getAgentCode() && agentCodes.length === 1) setDisabledOp(false);
      else setDisabledOp(true);
    }, [agentCodes, setDisabledOp]);

    return (
      <div className={styles.filterContainer}>
       {/* PACS Customization: PACSPFAP-3081 | [Phase-1] | Start - Removes the downline selector for phase 1 */}
        {/* <div className={styles.selectorConatiner}>
          <SelectDownline
            campaignId={campaignId}
            currentOption={
              cacheParams?.current?.filterState.downline ?? [
                { label: '', value: '' },
                { label: '', value: globalStore.getAgentCode() },
              ]
            }
            onChange={(option: Array<Option>, codeArr: Array<string>) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.downline = option;
              updatedLeadState.agentCodes = codeArr;
              setAgentCodes(codeArr);
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
            campaignType={CampaignTypeEnum.normal}
          />
        </div> */}
        {/* PACS Customization: PACSPFAP-3081 | [Phase-1] | End - Removes the downline selector for phase 1 */}
        <Typography fontSize={16} fontWeight={600} marginBottom={'24px'}>
        {/* PACS Customization: PACSPFAP-3081 | Updated Label */}
          {Translation('campaign.detail.leadOverview')}
        </Typography>

        <div className={styles.selectorConatiner}>
          <CategorySelectorComponent
            campaignId={campaignId}
            agentCodes={agentCodes}
            onChange={(e) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.leadState = e;
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
            value={cacheParams?.current?.filterState?.leadState || 'total'}
          />
        </div>

        <Typography fontSize={16} fontWeight={600} marginBottom={'24px'}>
          {Translation('details')}
        </Typography>
      </div>
    );
  },
);

export const MarketingCampaignLeadListComponent: React.FC<ComponentProps> = memo(
  ({ data, cacheParams, campaignId }) => {
    const { columns, getData, rowSelection, onFilterChange, oppProviderValue, setDisabledOp, disabledOp } =
      useCampaignLeadList({
        data,
        cacheParams,
        campaignId,
      });
    const history = useHistory();
    const toLead = (i: any) => {
      if (!disabledOp) history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
    };
    const user: any = useSelector<any>((state) => state.auth.user);
    const Filter = useMemo(() => {
      return (props: FilterProps<any>) => (
        <TabLeadFilterComponent
          {...props}
          campaignId={campaignId}
          agentCode={user?.agentCode ?? ''}
          cacheParams={cacheParams}
          setDisabledOp={setDisabledOp}
        />
      );
    }, [cacheParams, user.agentCode, campaignId]);

    useEffect(() => {
      return () => {
        cacheParams?.current && (cacheParams.current = undefined);
      };
    }, []);

    return (
      <OperationContext.Provider value={oppProviderValue}>
        <ProFilterTable
          Filter={Filter}
          filterState={cacheParams?.current}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns}
          request={getData}
          enableRefresh={false}
          ActionNode={ActionItems}
          showSelect={false} //PACS Customization: PACSPFAP-3081 | [Phase-1] - Removes the checkbox column for phase 1
          onFilterChange={onFilterChange}
          onClickRow={toLead}
        />
      </OperationContext.Provider>
    );
  },
);
