import { DeadLeadReasonEnum, FollowUpStatusEnum } from 'src/app/modules/Leads/types/types';

export const followUpDeadLeadStatusToSubmissionStatus = (input: string, t: (val: string) => string): string => {
  switch (input) {
    case DeadLeadReasonEnum.agentNotInterested:
      return t('salesFuStatusToDeadReasons1');
    case DeadLeadReasonEnum.agentNoTime:
      return t('salesFuStatusToDeadReasons2');
    case DeadLeadReasonEnum.agentOutOfOffice:
      return t('salesFuStatusToDeadReasons3');
    case DeadLeadReasonEnum.agentOther:
      return t('salesFuStatusToDeadReasons4');

    case DeadLeadReasonEnum.contactCantConnect:
      return t('salesFuStatusEnumContact1');
    case DeadLeadReasonEnum.contactWrongNumber:
      return t('salesFuStatusEnumContact2');
    case DeadLeadReasonEnum.contactNoAnswer:
      return t('salesFuStatusEnumContact3');
    case DeadLeadReasonEnum.contactSmsNoRespond:
      return t('salesFuStatusEnumContact4');
    case DeadLeadReasonEnum.contactOther:
      return t('salesFuStatusEnumContact5');

    case DeadLeadReasonEnum.metNotInterested:
      return t('salesFuStatusEnumMet1');
    case DeadLeadReasonEnum.metNoInsuranceNeed:
      return t('salesFuStatusEnumMet2');
    case DeadLeadReasonEnum.metDidntWantContacted:
      return t('salesFuStatusEnumMet3');
    case DeadLeadReasonEnum.metCantAffordInsurance:
      return t('salesFuStatusEnumMet4');
    case DeadLeadReasonEnum.metNoProductsMeetNeed:
      return t('salesFuStatusEnumMet5');
    case DeadLeadReasonEnum.metBoughtFromOtherInsurance:
      return t('salesFuStatusEnumMet6');
    case DeadLeadReasonEnum.metOther:
      return t('salesFuStatusEnumMet7');

    case DeadLeadReasonEnum.quoteNotInterested:
      return t('salesFuStatusEnumQuote1');
    case DeadLeadReasonEnum.quoteTooExpensive:
      return t('salesFuStatusEnumQuote2');
    case DeadLeadReasonEnum.quoteProductNotMeetNeed:
      return t('salesFuStatusEnumQuote3');
    case DeadLeadReasonEnum.quoteBoughtFromOtherInsurance:
      return t('salesFuStatusEnumQuote4');
    case DeadLeadReasonEnum.quoteNoRespond:
      return t('salesFuStatusEnumQuote5');
    case DeadLeadReasonEnum.quoteOther:
      return t('salesFuStatusEnumQuote6');

    case DeadLeadReasonEnum.proposalNotInterested:
      return t('salesFuStatusEnumProposal1');
    case DeadLeadReasonEnum.proposalNoInsuranceNeed:
      return t('salesFuStatusEnumProposal2');
    case DeadLeadReasonEnum.proposalBoughtFromOtherInsurance:
      return t('salesFuStatusEnumProposal3');
    case DeadLeadReasonEnum.proposalCantAffordInsurance:
      return t('salesFuStatusEnumProposal4');
    case DeadLeadReasonEnum.proposalProductNotMeetNeed:
      return t('salesFuStatusEnumProposal5');
    case DeadLeadReasonEnum.proposalNotShowUp:
      return t('salesFuStatusEnumProposal6');
    case DeadLeadReasonEnum.proposalOther:
      return t('salesFuStatusEnumProposal7');

    case DeadLeadReasonEnum.policyUnderwritingFailed:
      return t('salesFuStatusEnumPolicy1');
    case DeadLeadReasonEnum.policyProspectChangedMind:
      return t('salesFuStatusEnumPolicy2');
    case DeadLeadReasonEnum.policyOther:
      return t('salesFuStatusEnumPolicy3');

    default:
      return input;
  }
};

export const formatFollowRejectData = (selectedIds: string[], t: (val: string) => string) => {
  let reason = '';
  const reasons: string[] = [];
  selectedIds.forEach((item) => {
    reason = `${reason ? reason + ',' : reason}${followUpDeadLeadStatusToSubmissionStatus(item, t)}`;
    reasons.push(item.toString());
  });

  return {
    reasons,
    reason,
  };
};

export const statusToDeadReasons = (isOpportunity: boolean, status?: FollowUpStatusEnum): DeadLeadReasonEnum[] => {
  if (isOpportunity) {
    return [
      DeadLeadReasonEnum.agentNotInterested,
      DeadLeadReasonEnum.agentNoTime,
      DeadLeadReasonEnum.agentOutOfOffice,
      DeadLeadReasonEnum.agentOther,
    ];
  }
  switch (status) {
    case FollowUpStatusEnum.contact:
      return [
        DeadLeadReasonEnum.contactCantConnect,
        DeadLeadReasonEnum.contactWrongNumber,
        DeadLeadReasonEnum.contactNoAnswer,
        DeadLeadReasonEnum.contactSmsNoRespond,
        DeadLeadReasonEnum.contactOther,
      ];
    case FollowUpStatusEnum.met:
      return [
        DeadLeadReasonEnum.metNotInterested,
        DeadLeadReasonEnum.metNoInsuranceNeed,
        DeadLeadReasonEnum.metDidntWantContacted,
        DeadLeadReasonEnum.metCantAffordInsurance,
        DeadLeadReasonEnum.metNoProductsMeetNeed,
        DeadLeadReasonEnum.metBoughtFromOtherInsurance,
        DeadLeadReasonEnum.metOther,
      ];
    case FollowUpStatusEnum.quote:
      return [
        DeadLeadReasonEnum.quoteNotInterested,
        DeadLeadReasonEnum.quoteTooExpensive,
        DeadLeadReasonEnum.quoteProductNotMeetNeed,
        DeadLeadReasonEnum.quoteBoughtFromOtherInsurance,
        DeadLeadReasonEnum.quoteNoRespond,
        DeadLeadReasonEnum.quoteOther,
      ];
    case FollowUpStatusEnum.proposal:
      return [
        DeadLeadReasonEnum.proposalNotInterested,
        DeadLeadReasonEnum.proposalNoInsuranceNeed,
        DeadLeadReasonEnum.proposalBoughtFromOtherInsurance,
        DeadLeadReasonEnum.proposalCantAffordInsurance,
        DeadLeadReasonEnum.proposalProductNotMeetNeed,
        DeadLeadReasonEnum.proposalNotShowUp,
        DeadLeadReasonEnum.proposalOther,
      ];
    case FollowUpStatusEnum.policy:
      return [
        DeadLeadReasonEnum.policyUnderwritingFailed,
        DeadLeadReasonEnum.policyProspectChangedMind,
        DeadLeadReasonEnum.policyOther,
      ];
    default:
      return [];
  }
};

export const reasonsToOptions = (
  isOpportunity: boolean,
  t: (val: string) => string,
  status?: FollowUpStatusEnum,
): { label: string; value: string }[] => {
  const option = statusToDeadReasons(isOpportunity, status);
  return option.map((item) => {
    return {
      label: followUpDeadLeadStatusToSubmissionStatus(item, t),
      value: item,
    };
  });
};
