import { getViewPort, getBreakpoint } from './utils';

export const ROOT_PATH = `${window.envConfig['PORTAL_BASE_PATH']}/`;

export const NOT_FOUND_ERROR_PAGE_PATH = '/error/not-found';
export const FORBIDDEN_ERROR_PAGE_PATH = '/error/forbidden';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const VALIDATION_FIELD_ERROR_TEXT = 'Please fill in correct data';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';
export const TIME_ERROR_TEXT = 'End time must be after Start time';
export const INVALID_PATTERN_ERROR_TEXT = 'Invalid data. Please remove redundant symbols';
export const INVALID_CURRENCY_ERROR_TEXT = 'Please fill in currency symbol followed by numbers';
export const BLACKLISTED_POSTAL_CODE =
  'The postal code keyed in is from a blacklisted location. Kindly provide a new postal code';
export const INVALID_POSTAL_CODE_INPUT = 'Please enter a valid postal code';
export const NUMBER_NA_ONLY_ERROR_TEXT = 'Please enter a positive integer number or NA';
export const EMAIL_FORMAT_ERROR_TEXT = 'Please fill in a valid email address';
export const NUMBER_ONLY_ERROR_TEXT = 'Please enter a positive integer number';

export const constants = {
  Standard_DateFormat: 'YYYY-MM-DDTHH:mm:ssZ',
  Default_Date_Format: 'DD/MM/YYYY',
  Date_Minute_Scale_Format: 'DD/MM/YYYY HH:mm',
};

export enum Routes {
  AUTH = 'auth',
  SIGN_IN = 'signin',
  FORGOT_PASSWORD = 'forgotpassword',
  DISCLAIMER = 'disclaimer',
  PRIVACY_POLICY = 'privacypolicy',

  //  Home
  HOME = 'home',
  HOME_LANDING = 'homelanding',
  HOME_CalendarDetail = 'homecalendardetail',
  HOME_BirthdayReminder = 'homebirthdayreminder',
  HOME_BirthdayGreetScreen = 'BirthdayGreetScreen',
  HOME_BirthdayCardScreen = 'BirthdayCardScreen',

  COMMON_ResourceSearch = 'commonresourcesearch',

  //  Policy
  POLICY_LANDING = 'policylanding',
  POLICY_SEARCH_RESULT = 'policysearchresult',
  POLICY_BASIC_SEARCH = 'policysearch',
  POLICY_ADV_SEARCH = 'policyadvsearch',
  POLICY_CLIENT_OVERVIEW = 'policyclientoverview',
  POLICY_DETAIL = 'policydetail',
  PROPOSAL_DETAIL = 'proposaldetail',
  PROPOSAL_ITEM = 'proposalitem',
  PROPOSAL_FOLLOW = 'proposalfollow',
  PROPOSAL_CUSTOMER_FOLLOW_UP = 'proposalcustomerfollowup',
  PROPOSAL_WAIT_APPROVAL = 'proposalwaitapproval',
  POLICY_ITEM_LIST = 'policyitemlist',
  POLICY_CLAIM_DETAIL = 'policyclaimdetail',
  POLICY_CLIENT_DETAIL = 'policyclientdetail',
  PREMIUM_DUE_DATE = 'premiumduedate',

  //  Performance
  INCENTIVE = 'incentive',
  Performance_Manager = 'performancemanager',
  Performance_Incentive = 'performanceincentive',
  Performance_Edit = 'performanceedit',
  Performance_MDRT = 'performancemdrt',
  Performance_Ranking = 'performanceranking',
  Performance_Convention = 'performanceconvention',

  //  Recruitment
  RECRUITMENT = 'recruitmenthome',
  Recruitment_CandidateSearch = 'recruitmentcandidatesearch',
  Recruitment_CandidateAdd = 'recruitmentcandidateadd',
  Recruitment_CandidateDetail = 'recruitmentcandidatedetail',
  Recruitment_CandidateProfile = 'recruitmentcandidateprofile',
  Recruitment_Webview = 'recruitmentwebview',
  Recruitment_Resource = 'recruitmentresource',
  Recruitment_Resource_Webview = 'recruitmentresourcewebview',
  Recruitment_Resource_Set = 'recruitmentresourceset',
  Recruitment_AssessLinkShare = 'recruitmentassesslinkshare',
  Recruitment_InterviewMeeting = 'recruitmentinterviewmeeting',
  Recruitment_InterviewNotes = 'recruitmentinterviewnotes',
  Recruitment_ArrangeInterview = 'recruitmentarrangeinterview',
  Recruitment_TraningIndex = 'recruitmenttraningindex',
  Recruitment_TraningMyteam = 'recruitmenttraningmyteam',
  Recruitment_CandidateRecruitStatus = 'recruitmentcandidaterecruitstatus',
  Recruitment_InterviewDetail = 'recruitmentinterviewdetail',
  Recruitment_Choose_Contacts = 'recruitmentcandidatechoosecontacts',
  Recruitment_Pending_Candidates = 'recruitmentpendingcandidates',
  Recruitment_Pru_Application = 'recruitmentpruapplication',
  Recruitment_Career_Status = 'recruitmentcareerstatus',

  //  Training
  TRAINING = 'training',
  Training_MyQrCodeScreen = 'myqrcodescreen',
  Training_WebviewScreen = 'trainwebviewscreen',
  Training_PRUExpert = 'trainpruexpert',
  Training_CPDTrackerScreen = 'traincpdtrackerscreen',
  Training_TrackerInfoScreen = 'traintrackerinfoscreen',

  //  Inbox
  INBOX = 'inbox',
  INBOX_DETAIL = 'inboxdetail',
  INBOX_SEARCH = 'inboxsearch',

  //  Sales
  SALES_KIT = 'saleskit', // landing page(user promotion)
  SALES_KIT_RESOURCE_SEARCH = 'saleskitsearch', // search page
  SALES_KIT_RESOURCE_WEBVIEW = 'saleskitwebview', // open resource in webview
  SALES_KIT_SALES_RESOURCE = 'saleskitsalesreource', // sales resource
  SALES_KIT_ENAME_CARD = 'saleskitenamecard', // eName Card
  SALES_KIT_AGENT_PROFILE = 'saleskitagentprofile', // Agent Profile

  //  Common
  PROFILE = 'profile',
  ATTENDANCE = 'attendance',
  SETTINGS = 'settings',
  PRULEADS = 'pruleads',
  ASKPRU = 'askpru',
  WEBMAIL = 'webmail',
  PIL = 'pil',
  WEBVIEW = 'webview',
  NOTIFICATION_WEBVIEW = 'notificationwebview',
  ONLINE_VIDEO = 'onlinevideo',
  ONLINE_VIDEO_OVERVIEW = 'onlinevideooverview',
  DASHBOARD = 'dashboard',
  STAFFLOGON = 'stafflogon',
  AIS = 'ais',
  FEEDBACK = 'feedbackmain',
  NEWS = 'prunews',
}

export const AppRoutes = {
  //  Home
  'route.home.landing': Routes.HOME_LANDING,
  'route.home.calendar': Routes.HOME_CalendarDetail,
  // "route.customer.premiumduedate": Routes.PREMIUM_DUE_DATE,

  // "route.customer.home": Routes.POLICY_LANDING,
  // "route.customer.pendingtask": Routes.PROPOSAL_CUSTOMER_FOLLOW_UP,

  //  Performance
  // "route.performance.home": Routes.INCENTIVE,
  // 'route.performance.manager': Routes.Performance_Manager,
  // "route.performance.incentive": Routes.Performance_Incentive,
  // "route.performance.targetedit": Routes.Performance_Edit,
  // "route.performance.mdrt": Routes.Performance_MDRT,
  //  Not yet support
  // "route.performance.ranking": Routes.Performance_Ranking,
  // "route.performance.convention": Routes.Performance_Convention,

  'route.recruitment.home': Routes.RECRUITMENT,
  'route.recruitment.newcandidate': Routes.Recruitment_CandidateAdd,
  'route.recruitment.resource': Routes.Recruitment_Resource,
  'route.recruitment.pendingcandidate': Routes.Recruitment_Pending_Candidates,

  'route.training.home': Routes.TRAINING,
  //"route.training.qrcode": Routes.ATTENDANCE,
  'route.training.pruexpert': Routes.Training_PRUExpert,
  //"route.training.cpdtracker": Routes.Training_CPDTrackerScreen,

  //"route.inbox": Routes.INBOX,
  // 'Inbox Detail': Routes.INBOX_DETAIL,
  'route.setting': Routes.SETTINGS,
  //"route.webmail": Routes.WEBMAIL,
  //"route.askpru": Routes.ASKPRU,
  //"route.pil": Routes.PIL,
  //"route.feedback": Routes.FEEDBACK,
  //"route.news": Routes.NEWS,

  // "route.sale.home": Routes.SALES_KIT, // landing page(user promotion)
  //"route.sale.resouce": Routes.SALES_KIT_SALES_RESOURCE, // sales resource
  //"route.sale.enamecard": Routes.SALES_KIT_ENAME_CARD, // eName Card
  // "route.sale.pulseleads": Routes.PRULEADS,
};

export const isPhone = getViewPort().width < getBreakpoint('lg') && navigator.userAgent.match(/iPad/i) === null;

export enum LOCAL_STORAGE_KEYS {
  agentInfo = 'GlobalHelper.authState',
}

export const trueFalseOptions = ['true', 'false'];
