import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { leadLabelsTag, renderOp, renderRemark, renderStatus } from './lead-column-component.util';
import { renderSLATime } from 'src/app/modules/Leads/util/table-columns-render.util';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';
import { Columns } from 'src/app/modules/Leads/types/types';

export const getColumns = ({
  t,
  disabledOp,
}: {
  t: (val: string) => string;
  disabledOp?: boolean;
}): ProColumns<CampaignLeadItem>[] => [
  {
    title: t('campaign.list.name'),
    dataIndex: 'name',
    width: '290px',
  },
  {
    title: t('campaign.list.status'),
    dataIndex: 'status',
    width: '466px',
    render: (currValue: string, item) => {
      return renderStatus(currValue, item, t);
    },
  },
  // PACS Customization: PACSPFAP-3081 | Start
  // {
  //   title: t('campaign.list.sla'),
  //   dataIndex: 'sla',
  //   width: '176px',
  //   render: renderSLATime,
  // },
  // PACS Customization: PACSPFAP-3081 | End
  {
    ...COLUMN_ITEMS[Columns.source](),
    title: t(COLUMN_ITEMS[Columns.source]().title as string),
  } as any,
  {
    ...COLUMN_ITEMS[Columns.leadSource](),
    title: t(COLUMN_ITEMS[Columns.leadSource]().title as string),
  } as any,
  {
    title: t('campaign.list.remark'),
    dataIndex: 'remark',
    width: '290px',
    render: (currValue: any[], item) => {
      return renderRemark(currValue, item, t);
    },
  },
  {
    title: t('campaign.list.leadLabel'),
    dataIndex: 'labels',
    width: '176px',
    render: (currValue: string[]) => {
      if (currValue.length > 0) {
        return leadLabelsTag(currValue, t);
      } else return '';
    },
  },

  // {
  //   title: t('campaign.list.leadSource'),
  //   dataIndex: 'leadSource',
  //   width: '176px',
  //   sorter: true,
  // },

  {
    title: t('campaign.list.lastView'),
    dataIndex: 'lastViewedAt',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('campaign.list.lastUpdated'),
    dataIndex: 'updatedAt',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  //PACS Customization: PACSPFAP-3081 | [Phase-1] | Start - Removes the Operation column for phase 1
  // {
  //   title: t('prospect.appoinment.title.operation'),
  //   width: '176px',
  //   dataIndex: 'op',
  //   sticky: true,
  //   render: (currValue: string, item: any, action: any) => {
  //     return disabledOp ? null : renderOp(currValue, item, action);
  //   },
  // },
  //PACS Customization: PACSPFAP-3081 | [Phase-1] | End - Removes the Operation column for phase 1
];
