import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, set } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useEventEmitter, useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { FollowUpStatusEnum, Lead } from 'src/app/modules/Leads/types/types';
import {
  followUpStatusToSubmissionStatus,
  followUpStatusToTimelineNumber,
  getDeadReasonsByStatus,
  getStageBarList,
} from 'src/app/modules/Leads/util/lead-status/lead-status.util';
import moment from 'moment';
import dayjs from 'dayjs';
import { patchFollowUp } from 'src/app/modules/Leads/network/leadsCrud';
import { formatFollowRejectData } from 'src/app/modules/Leads/pages/leads/leads-list/util/helper.util';
import { allGaDataClick2, allGaDataClick3 } from 'src/app/common/ga/types/ga-click-lead';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { OperationContext } from '../../../lead-operation-units/util/operation-context.util';

interface HookProps {
  lead: Lead;
  updateCallback?: () => void;
}

export interface PostFollowUpReq {
  status: string;
  submissionDate: string;
  reason?: string;
  reasons?: (string | number)[];
  policyNumber?: string;
  issuedPolicy?: (issuedPolicy | null)[];
}

export interface issuedPolicy {
  policyNumber: string;
  issuedDate?: any;
}

enum ISSUED_STATUS {
  POLICY_ISSUE = 'policy',
  DEAD = 'dead',
}

const MAX_POLICY_NUM = 9;

export const useLeadStatusUpdateDialog = ({ lead, updateCallback }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [updateTime, setUpdateTime] = useState<Date>(new Date());
  const [policyNum, setPolicyNum] = useState<Array<string>>([]);
  const [selectedReason, setSelectedReason] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const statusOptions = useMemo(() => {
    const statusArr = getStageBarList(true);
    const currentTimelineNumber = followUpStatusToTimelineNumber(lead.followUpStatus as FollowUpStatusEnum);
    return statusArr.map((status: string) => ({
      label: Translation(`campaign.lead.followUp.${status}`),
      value: status,
      disabled: currentTimelineNumber > followUpStatusToTimelineNumber(status as FollowUpStatusEnum),
    }));
  }, []);

  const deadReasonOptions = useMemo(() => {
    const options = getDeadReasonsByStatus(followUpStatusToSubmissionStatus(lead.followUpStatus as FollowUpStatusEnum));
    return options.map((status: string) => ({
      label: Translation(`lead_dead_reason_${status}`),
      value: status,
    }));
  }, [lead]);

  const onStatusChange = useCallback((status: string) => {
    if (status === ISSUED_STATUS.POLICY_ISSUE) {
      setPolicyNum(Array(1).fill(''));
    }
    setSelectedReason([]);
    setSelectedStatus(status);
  }, []);

  const onUpdateTimeChange = useCallback((date: dayjs.Dayjs | null) => {
    if (date) {
      setUpdateTime(date.toDate());
    }
  }, []);

  const onPolicyNumberInput = useCallback((value: string, index: number) => {
    setPolicyNum((prev) => {
      const newPolicyNum = [...prev];
      newPolicyNum[index] = value;
      return newPolicyNum;
    });
  }, []);

  const onSelectReason = useCallback((reason: string) => {
    setSelectedReason((prev) => {
      if (prev.includes(reason)) {
        return prev.filter((item) => item !== reason);
      } else {
        return [...prev, reason];
      }
    });
  }, []);

  const addPolicyNumInput = useCallback(() => {
    if (policyNum.length < MAX_POLICY_NUM) {
      setPolicyNum([...policyNum, '']);
    }
  }, [policyNum]);

  const resetStatus = useCallback(() => {
    setSelectedStatus('');
    setUpdateTime(new Date());
    setPolicyNum([]);
    setSelectedReason([]);
  }, []);

  const isDead = useMemo(() => selectedStatus === ISSUED_STATUS.DEAD, [selectedStatus]);
  const isPolicy = useMemo(() => selectedStatus === ISSUED_STATUS.POLICY_ISSUE, [selectedStatus]);

  const canSubmit = useMemo(() => {
    if (isDead) {
      return selectedReason.length > 0;
    }
    if (isPolicy) {
      return policyNum[0] !== '' && policyNum.length > 0;
    }
    return selectedStatus !== '';
  }, [selectedStatus, updateTime, policyNum, selectedReason, isDead, isPolicy]);

  const { event } = useContext(OperationContext);

  const updateLead = async () => {
    let patchData: PostFollowUpReq | any = {};
    setLoading(true);
    if (isDead) {
      const { reason, reasons } = formatFollowRejectData(selectedReason, Translation);
      patchData = {
        status: selectedStatus,
        submissionDate: moment().format(),
        reason,
        reasons,
      };
    } else {
      const issuedPolicy: issuedPolicy[] | undefined = isPolicy
        ? policyNum
            .filter((policy: any) => policy)
            .map((item: any, index: number) => {
              if (index === 0)
                return {
                  policyNumber: item,
                  issuedDate: moment(updateTime).format(),
                };
              return {
                policyNumber: item,
              };
            })
        : undefined;
      patchData = {
        status: selectedStatus,
        submissionDate: moment(updateTime).format(),
        policyNumber: isPolicy ? policyNum[0] : undefined,
        issuedPolicy,
      };
    }
    patchFollowUp(lead._id, patchData)
      .then((res) => {
        updateCallback && updateCallback();
        onUpdateGa(selectedStatus);
        event && event.emit();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUpdateGa = (selectedStatus: any) => {
    const status = Translation(`campaign.lead.followUp.${selectedStatus}`);
    const gaClickData = {
      ...allGaDataClick2,
      object_name: status,
      extraInfo: {
        followup_status: status,
        prospect_category: lead?.prospect?.prospectCategories?.type ?? 'None',
        campaign: lead?.campaign?.name,
      },
    };
    const gaClickData1 = {
      ...allGaDataClick3,
      object_name: status,
    };
    takeUIClickEvent(gaClickData, 'update lead');
    takeUIClickEvent(gaClickData1);
  };

  return {
    loading,
    statusOptions,
    deadReasonOptions,
    policyNum,
    isDead,
    isPolicy,
    canSubmit,
    onStatusChange,
    onUpdateTimeChange,
    onPolicyNumberInput,
    onSelectReason,
    addPolicyNumInput,
    resetStatus,
    updateLead,
  };
};
