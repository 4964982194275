import React, { FC, memo, useMemo } from 'react';
import { Lead } from 'src/app/modules/Leads/types/types';
import { AppointmentButton } from '../components/appoitment-button/appointment-button.component';
import { UpdateLeadButton } from '../components/update-lead-button/update-lead-button.component';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
};

export const LeadFollowUpOperation: React.FC<ComponentProps> = memo(({ item, actions }: ComponentProps) => {
  return (
    <>
      <AppointmentButton item={item} actions={actions} />
      <UpdateLeadButton item={item} actions={actions} />
    </>
  );
});
