import { PaginateList } from 'src/app/common/types/common-types';

// column of table
export enum Columns {
  displayName = 'displayName',
  typeOfLead = 'typeOfLead',
  sla = 'expiredAt',
  campaignName = 'campaignName',
  leadSource = 'sourceName',
  lastViewedAt = 'lastViewedAt',
  updatedAt = 'updatedAt',
  operation = 'operation',
  opportunityOperation = 'opportunityOperation',
  followUpOperation = 'followUpOperation',
  prospectLeadOperation = 'prospectLeadOperation',
  leadStatus = 'leadStatus',
  remark = 'remark',
  noLabelRemark = 'noLabelRemark', // PACS Customization: PACSPFAP-3089
  dropDate = 'dropDate',
  source = 'source',
}

export type CampaignPaginateList<T extends Record<string, any>> = Omit<PaginateList<T>, 'docs'> & {
  campaigns: PaginateList<T>['docs'];
};

export interface OpportunityQueryParams {
  sourceList?: string[];
  campaignList?: string[];
  leadType?: string;
  categories?: string[];
  limit?: number;
  page: number;
}

export interface FollowUpQueryParams {
  phoneNumber?: string;
  displayName?: string;
  isAligning?: boolean;
  updatedAtGreaterThenEqual?: string;
  sourceList?: string[] | null;
  agentSourceList?: string[] | null;
  lastFollowUpStatus?: string[];
  campaignList?: string[];
  categories?: string[];
  page?: number;
  limit?: number;
  isActiveOnly?: boolean;
  notLastFollowUpStatus?: string[];
}

export interface ClosedQueryParams {
  phoneNumber?: string;
  displayName?: string;
  isAligning?: boolean;
  updatedAtGreaterThenEqual?: string;
  sourceList?: string[] | null;
  agentSourceList?: string[] | null;
  lastFollowUpStatus?: string[];
  campaignList?: string[];
  categories?: string[];
  limit?: number;
}

export interface OpportunityList {
  sections: Section[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
  isAcceptLeads: boolean;
}

export interface FollowUpList {
  leads: (Lead & { prospectMaster: Lead['prospect'] })[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
}

export interface ClosedLeadList {
  leads: Lead[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
}

export interface CommonLeadList {
  lead: (Lead & { prospectMaster: Lead['prospect'] } & { prospectDetail: Lead['prospect'] })[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
}

export interface Section {
  type: string;
  leads: Lead[];
}

interface Campaign {
  _id: string;
  campaignId: string;
  name: string;
  description: string;
  isLeadWithPartialInfo?: boolean;
  leadWithPartialInfoWarning?: {
    title?: string;
    body?: string;
    tnc?: string;
  };
  image?: { url?: string };
}

export enum AgentProspectStatus {
  followUp = 'followUp',
  engagement = 'engagement',
}

export interface Lead {
  _id: string;
  type: string;
  campaign: Campaign;
  prospect: Prospect;
  expiredAt: string;
  labels: string[];
  dropDate?: string;
  leadWithPartialInfoDisplay: boolean;
  agentStatus: string;
  agentStatusUpdatedAt?: string;
  leadSource: string; // some api return leadSource instead of sourceName
  sourceName: string;
  sourceImage?: { url?: string };
  followUp: Array<{ status: string; reason?: string; reasons?: string[]; issuedPolicy?: { policyNumber: string }[] }>;
  followUpStatus: string;
  lastFollowUpStatus: string; // for follow up and closed
  issuedPolicy: Array<{ policyNumber: string }>;
  image: { url?: string };
  prospectDetail: {
    alias: string;
    firstName: string;
    gender: string;
    lastName: string;
    phoneNumber: string;
    types: string[];
  };
  leadSubSource?: {
    sourceName?: string;
  };
  // isEngaged?: boolean; // for follow up
  // agentProspectStatus?: AgentProspectStatus; // for follow up
}

export interface Prospect {
  _id?: string;
  passportNumber?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  type?: string;
  birthDate?: Date;
  gender?: string;
  maritalStatus?: string;
  language?: string;
  race?: string;
  nationality?: string;
  descriptionOfResidentStats?: string;
  occupation?: string;
  company?: string;
  jobTitle?: string;
  income?: string;
  education?: string;
  smokeHabit?: string;
  totalChild?: number;
  countryCode?: string;
  phoneNumber?: string;
  secondPhoneNumber?: string;
  policyPhoneNumber?: string;
  email?: string;
  secondEmail?: string;
  policyEmail?: string;
  fbProfile?: string;
  wechatId?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  notes?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  countryNationalId?: String;
  displayName?: string;
  prospectCategories?: any;
}

export enum TabValues {
  opportunities = 'opportunity',
  followUp = 'followUp',
  closed = 'closed',
}

export interface SourceList {
  _id: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  migratedId: string;
  migratedImage: string;
  migratedType: string;
  sourceId: string;
  sourceName: string;
  updatedAt: string | null;
  fromPulse?: boolean;
  regionalLeadSource?: string;
  channelCode?: null;
}

export interface AgentCampaignList {
  _id: string;
  campaignId: string;
  name: string;
}

export interface AgentSourceList {
  id: string;
  sourceName: string;
}

export enum AgentStatusEnum {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export enum FollowUpStatusEnum {
  engagement = 'engagement',
  contact = 'contact',
  met = 'met',
  quote = 'quote',
  proposal = 'proposal',
  policy = 'policy',
  issued = 'issued',
  dead = 'dead',
  contactFailure = 'contactFailure',
  pendingToContact = 'pendingToContact',
  pending = 'pending', // only for UI mapping, no this one in DB
}

export enum DeadLeadReasonEnum {
  agentNotInterested = 'agentNotInterested',
  agentNoTime = 'agentNoTime',
  agentOutOfOffice = 'agentOutOfOffice',
  agentOther = 'agentOther',

  contactCantConnect = 'contactCantConnect',
  contactWrongNumber = 'contactWrongNumber',
  contactNoAnswer = 'contactNoAnswer',
  contactSmsNoRespond = 'contactSmsNoRespond',
  contactOther = 'contactOther',

  metNotInterested = 'metNotInterested',
  metNoInsuranceNeed = 'metNoInsuranceNeed',
  metDidntWantContacted = 'metDidntWantContacted',
  metCantAffordInsurance = 'metCantAffordInsurance',
  metNoProductsMeetNeed = 'metNoProductsMeetNeed',
  metBoughtFromOtherInsurance = 'metBoughtFromOtherInsurance',
  metOther = 'metOther',

  quoteNotInterested = 'quoteNotInterested',
  quoteTooExpensive = 'quoteTooExpensive',
  quoteProductNotMeetNeed = 'quoteProductNotMeetNeed',
  quoteBoughtFromOtherInsurance = 'quoteBoughtFromOtherInsurance',
  quoteNoRespond = 'quoteNoRespond',
  quoteOther = 'quoteOther',

  proposalNotInterested = 'proposalNotInterested',
  proposalNoInsuranceNeed = 'proposalNoInsuranceNeed',
  proposalBoughtFromOtherInsurance = 'proposalBoughtFromOtherInsurance',
  proposalCantAffordInsurance = 'proposalCantAffordInsurance',
  proposalProductNotMeetNeed = 'proposalProductNotMeetNeed',
  proposalNotShowUp = 'proposalNotShowUp',
  proposalOther = 'proposalOther',

  policyUnderwritingFailed = 'policyUnderwritingFailed',
  policyProspectChangedMind = 'policyProspectChangedMind',
  policyOther = 'policyOther',
}
export interface LeadForm {
  campaign: string;
  source: string;
  dateTs: number;
  refererredSource: string;
  description: string;
  interestedProduct: string;
  qualification: string;
  financialNeeds: string;
  expenses: string;
  insuranceProducts: string;
  contactMethod: string;
  contactDay: string;
  contactTime: string;
  otherLeadComments: string;
  campaignCategory: string;
  campaignType: string;
  campaignName: string;
  leadSource: string;
  leadSubSource: string;
}

export interface PoliciesResponseModel {
  policyNo: string;
  productName: string;
  status: string;
  AFYP: string;
  polOwnerCode: string;
  polOwnerName: string;
  laClientCode: string;
  laClientName: string;
  statusCode: number;
}

export enum PolicyListSearchOptions {
  ALL = '1',
  CLIENT_NO = '2',
  SUBMISSION_DATE = '3',
  HKID = '4',
  CLIENT_NAME = '5',
}

export interface RequestProspectLead {
  prospect: string;
  leadDate: string;
  agentLeadSource?: string | number;
  interestedProduct?: string;
  referredSource?: string;
  financialNeeds?: string;
  expenses?: string;
  insuranceProducts?: string;
  contactMethod?: string;
  contactDay?: string;
  contactTime?: string;
  description?: string;
  qualification?: string;
  otherLeadComments?: string;
  referredClientId?: string;
  referredClientName?: string;
  referredProspectId?: string;
  referredProspectName?: string;
  campaign?: string;
  source?: string;
  category: string;
}
export interface FollowUp {
  createdAt?: Date;
  status?: string;
  submissionDate?: Date;
  reason?: string;
  reasons?: string[];
}
export enum LeadCategoryEnum {
  SALES_LEAD = 'SalesLead',
  RECRUIT_LEAD = 'RecruitLead',
}

export enum ReferredTypeEnum {
  prospect = 'prospect',
  client = 'client',
}

export enum LeadSourceEnum {
  marketingLeadSource = 'Marketing Lead Source',
  agentSourced = 'Agent Sourced',
  dpasLeadSource = 'DPAS Lead Source',
  corporateLeadSource = 'Corporate Website Lead Source',
  pulseLeadSourced = 'Pulse Lead Source',
  otherSource = 'Other Source',
}

export interface GetLeadsByGroupReqDto {
  groupCode?: string;
  pagination?: boolean;
  limit?: number;
  page?: number;
}

export enum LeadStatusEnum {
  WAITING_FOR_ACCEPT = 'waitingForAccept',
  PENDING_FOR_CONTACT = 'pendingForContact',
  /**
   * corresponds to followup.contactSuccess
   */
  CONTACT_SUCCESS = 'contactSuccess',
  /**
   * corresponds to followup.met
   */
  MET = 'met',
  /**
   * corresponds to followup.quote
   */
  QUOTE = 'quote',
  /**
   * corresponds to followup.proposal
   */
  PROPOSAL = 'proposal',
  /**
   * corresponds to followup.policy
   */
  POLICY = 'policy',
  /**
   *  accept expired
   */
  ACCEPT_EXPIRED = 'acceptExpired',
  /**
   *  contact expired
   */
  CONTACT_EXPIRED = 'contactExpired',
  /**
   * corresponds to followup.contactfailure
   */
  DEAD = 'dead',
  REJECTED = 'rejected',
  PENDING_FOR_SERVICE = 'pendingForService',
}

export interface ReassignLead {
  leadId: string;
  updateActivityStatus: UpdateActivityStatus;
}

export enum UpdateActivityStatus {
  KEEP_SAME = 'keepSameActivityStatus',
  UPDATE_TO_PENDING = 'updateToPendingContact',
}

export enum ReassignLeadsListTypeEnum {
  ALL = 'all',
  PARTIAL = 'partial',
}

export interface ReassignLeadsRequest {
  groupCode?: string;

  leads: ReassignLead[];
  /**
   * Determine whether all leads of the current unit or part of the leads are selected.
   * If all leads are selected,
   * all leads need to be obtained at the agentexp layer.
   * If they are partial, the leads in the body are directly used.
   */
  type: ReassignLeadsListTypeEnum;

  toAgent: string;

  comment?: string;
}

export interface ProspectLeadParams {
  page: number;
  limit: number;
  tab?: string;
  sortBy?:
    | 'displayName'
    | 'campaignName'
    | 'sourceName'
    | 'receiveDate'
    | 'updateDate' // can also use updatedAt
    | 'lastViewedAt'
    | 'lastViewedAt'
    | 'followUpTab';
  sortOrder?: 1 | -1;
  prospect?: string;
}

export interface AutoDropLeadParams {
  page: number;
  limit: number;
  tab?: 'opportunity' | 'followUp';
  includeMassLead?: boolean;
  sortBy?: 'displayName' | 'campaignName' | 'lastViewedAt' | 'updateDate'; // default is updateDate(can also use updatedAt)
  sortOrder?: 1 | -1;
}

export interface AutoDropFollowList {
  lead: (Lead & { prospectMaster: Lead['prospect'] } & { prospectDetail: Lead['prospect'] })[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
  totalAutoDropLeadDay?: number;
  reminderAutoDropLeadDay?: number;
}
