import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, forEach } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchCampaignDetail } from '../../../network/campaignCurd';
import { CampaignDetail, CampaignLeadItem, FollowUpStatusEnum } from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';
import {
  marketingCampaignDetailsGaDataClick2,
  marketingCampaignDetailsGaDataClick3,
  marketingCampaignDetailsGaDataClick,
  marketingCampaignDetailsGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';

interface HookProps {
  id: string;
  // other params
}

export const useDetailPage = ({ id }: HookProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, error, loading, run, runAsync } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: true,
  });

  useEffect(() => {
    run();
  }, []);

  const navToList = () => {
    history.goBack();
  };

  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: marketingCampaignDetailsGaDataClick2,
        reject: marketingCampaignDetailsGaDataClick3,
        update: marketingCampaignDetailsGaDataClick,
        newApp: marketingCampaignDetailsGaDataClick1,
      },
    }),
    [],
  );

  return {
    loading,
    data,
    run,
    navToList,
    oppProviderValue,
  };
};
